import { Paper } from "@mui/material";
import { useEffect, useState } from "react";

import StoreMap from '../../tools/StoreMap';
import { mapBackgroundColor } from "../../utils/colors";


export default function NavigationMap(props) {
    const {
        storeMap,
        storeCategories,
        taskType,
        heightRef,
        highlightedBoxes = [],
        isZoomActive,
        taskData,
    } = props;

    const [categoriesOnMap, setCategoriesOnMap] = useState({});
    const [poses, setPoses] = useState([]);

    const transformRobot2Pixels = (xRobot, yRobot, angleRobot, origin, resolution, height) => {
        let ang = angleRobot + Math.PI / 2,
            x = (xRobot - origin[0]) / resolution,
            y = height - ((yRobot - origin[1]) / resolution);

        return [x, y, ang];
    };

    // Lyfecycle methods
    useEffect(() => {
        if (poses.length === 0) {
            let poses_temp = storeMap.task_table_info;
            let height = storeMap.map_info.map_original_size[0];
            let width = storeMap.map_info.map_original_size[1];

            const updatedPoses = poses_temp.map((pose) => {
                let x, y, ang;
                if (storeMap.map_info.rotated) {
                    [x, y, ang] = transformRobot2Pixels(
                        pose.x,
                        pose.y,
                        0,
                        storeMap.map_info.origin,
                        storeMap.map_info.resolution,
                        height,
                    );
                    x = width - 1 - x;
                    return { ...pose, x, y, angle: ang };
                } else {
                    [x, y, ang] = transformRobot2Pixels(
                        pose.x,
                        pose.y,
                        0,
                        storeMap.map_info.origin,
                        storeMap.map_info.resolution,
                        height,
                    );
                    return { ...pose, x, y, angle: ang };
                }
            });

            setPoses(updatedPoses);
        }
    }, [storeMap]);

    useEffect(() => {
        if (taskData?.length) {
            const categoryAux = mapCategoriesLayout(storeMap.map_info, taskData, taskType === 'promotions');
            setCategoriesOnMap(categoryAux);
        }
    }, [storeMap])

    // Methods
    const mapCategoriesLayout = (mapInfo, taskTableInfo, parseCategories = false) => {
        const categoryAux = {};
        if (storeCategories?.length > 1) {
            const scale_valueX = mapInfo.scale_value[0],
                scale_valueY = mapInfo.scale_value[1],
                category_i = parseCategories ? `store_category_${storeCategories[1].level}` : `category_${storeCategories[1].level}`;

            storeCategories.forEach(categoryInfo => {
                categoryAux[categoryInfo.value] = { name: categoryInfo.category_name };
            })
            taskTableInfo.forEach(detectionInfo => {
                if (detectionInfo.map_point) {
                    // separating darkStore of the rest of store to have two categories on the map
                    let categoryValue, categoryInfo;
                    if (detectionInfo.aisle?.includes('DS')) {
                        categoryValue = `${detectionInfo[category_i]}-DS`;
                        categoryInfo = categoryAux[categoryValue];
                        // TODO: check why something not come the name of the category like: J508 27-07-2022
                        if (!categoryInfo) {
                            if (categoryAux[detectionInfo[category_i]]?.name) {
                                categoryAux[categoryValue] = { name: categoryAux[detectionInfo[category_i]].name }
                                // categoryAux[categoryValue] = { name: 'No detectado' }
                            }
                        };
                    } else {
                        categoryValue = detectionInfo[category_i];
                        categoryInfo = categoryAux[categoryValue];
                    }

                    const map_pointX = detectionInfo.map_point[0],
                        map_pointY = detectionInfo.map_point[1];
                    if (categoryInfo && categoryInfo.map_point) {
                        const totalAlerts = categoryInfo.totalAlerts;
                        categoryInfo.totalAlerts++;
                        categoryInfo.map_point[0] = (categoryInfo.map_point[0] * (totalAlerts - 1) + map_pointX * scale_valueX) / totalAlerts;
                        categoryInfo.map_point[1] = (categoryInfo.map_point[1] * (totalAlerts - 1) + map_pointY * scale_valueY) / totalAlerts;
                    } else if (categoryInfo) {
                        categoryInfo.map_point = [map_pointX * scale_valueX, map_pointY * scale_valueY];
                        categoryInfo.totalAlerts = 1;
                    }
                }
            });
        }
        return categoryAux;
    }

    return (
        <Paper ref={heightRef} style={{ position: 'relative', width: '100%', height: 'fit-content', padding: '0 1em', backgroundColor: mapBackgroundColor() }}>
            <StoreMap
                storeMap={storeMap.map_info}
                tasksData={taskData}
                groupingAlertsBy={storeMap.GROUPINGALERTSBY}
                categoriesOnMap={categoriesOnMap}
                visualizationMode={'ds_nav_map'}
                isZoomActive={isZoomActive}
                selectedDots={poses}
                highlightedBoxes={highlightedBoxes}
                tastType={taskType}
            />
        </Paper>
    );
}
