import { getUserStores, getUserSuppliers } from 'core/slices/users';
import {
    getAislePromotions,
    getAllLandmark,
    getCoords,
    getLandmarks,
    getWallImage,
    getWallsAisles,
    setAisleLandmarkState,
    setProductState,
    setWallLandmarks,
    getScannedBaysInfo,
} from 'core/slices/wall';
import { connect } from 'react-redux';
import WallSelector from '../../../../components/features/digitalShelf/WallSelector';

import {
    getStoreMaps,
} from 'core/slices/digitalStore';

import {
    getStoreAvailableSessions,
    getStoresFloor,
} from 'core/slices/store';

import { setLinkState } from 'core/slices/link';
import { setSnackAlert } from 'core/slices/snackAlert';

import {
    getAisleLandmarkState,
    getAllLandmarks,
    getClientFormats,
    getCoordsInfo,
    getLinkState,
    getScannedBaysData,
    getSelectedClient,
    getSelectedWallProduct,
    getStoreAvailableSessionsState,
    getStoreFloor,
    getStoreMapsObject,
    getUserStoresAllList,
    getWallLandmarks,
    getWallsAislesData,
    isLoadingAislesData,
    isLoadingClientFormats,
    isLoadingCoordsInfo,
    isLoadingLandmark,
    isLoadingMaps,
    isLoadingUserStores,
    isLoadingUserSuppliers,
    isLoadingWallLandmarks,
    isStoreAvailableSessionsLoading,
    isStoreFloorLoading,
    isLoadingScannedBaysData,
    userSuppliers,
} from 'core/selectors';

export default connect(state => ({
    userStores: getUserStoresAllList(state),
    selectedClient: getSelectedClient(state),
    aislesData: getWallsAislesData(state),
    wallLandmarks: getWallLandmarks(state),
    coordsInfo: getCoordsInfo(state),
    selectedProduct: getSelectedWallProduct(state),
    linkReceived: getLinkState(state),
    aisleLandmarks: getAisleLandmarkState(state),

    isLoadingUserStores: isLoadingUserStores(state),
    isLoadingAislesData: isLoadingAislesData(state),
    isLoadingLandmarks: isLoadingWallLandmarks(state),
    landmarkData: getAllLandmarks(state),
    isLoadingLandmark: isLoadingLandmark(state),
    isLoadingCoordsInfo: isLoadingCoordsInfo(state),

    suppliers: userSuppliers(state),
    isLoadingSuppliers: isLoadingUserSuppliers(state),
    isLoadingMaps: isLoadingMaps(state),
    storeMaps: getStoreMapsObject(state),
    clientFormats: getClientFormats(state),
    isLoadingClientFormats: isLoadingClientFormats(state),

    storesFloor: getStoreFloor(state),
    isStoreFloorLoading: isStoreFloorLoading(state),
    scannedBaysData: getScannedBaysData(state),
    isLoadingScannedBaysData: isLoadingScannedBaysData(state),


    storeAvailableSessions: getStoreAvailableSessionsState(state),
    isStoreAvailableSessionsLoading: isStoreAvailableSessionsLoading(state),
}), {
    getUserStores,
    getWallsAisles,
    getWallImage,
    getLandmarks,
    getAllLandmark,
    getCoords,
    setWallLandmarks,
    setLinkState,
    setProductState,
    getAislePromotions,
    setAisleLandmarkState,
    getUserSuppliers,
    getStoreMaps,
    getStoresFloor,
    getStoreAvailableSessions,
    setSnackAlert,
    getScannedBaysInfo
})(WallSelector);
