import { ExcelExporter } from '../services/excelExporter';
import { createReducer } from './utils';

import {
    DIGITAL_SHELF_AVAILABLE_EXCEL,
    SNACK_SET,
    WALL_AVAILABLE,
    WALL_REQUEST,
    WALL_UNAVAILABLE,
} from './constants';

import { handleAvailable, handleRequest, handleUnavailable } from '../reducerHandlers';

const defaultState = {
    loadingAisles: false,
    loadingWallImageData: false,
    loadingWallLandmarksData: false,
    loadingLandmarkData: false,
    loadingCoordsInfo: false,
    loadingSearchResults: false,
    loadingAislePromotions: false,
    aislesData: {},
    wallImageData: {},
    wallLandmarksData: {},
    landmarkData: {},
    coordsInfo: {},
    searchResults: [],
    selectedStore: null,
    selectedProduct: null,
    excelLandmarks: [],
    loadingLandmarksExcel: false,
    aislePromotions: [],
    aisleLandmarks: [],
    misplacedSignedImages: [],
    loadingMisplacedSignedImages: false,
    loadingCoordsForExcel: false,
    coordsForExcel: null,
    scannedBays: [],
    currentAislesAbortController: null
};

export const reducer = createReducer(defaultState, {
    [WALL_REQUEST]: handleRequest,
    [WALL_AVAILABLE]: handleAvailable,
    [WALL_UNAVAILABLE]: handleUnavailable,
    [DIGITAL_SHELF_AVAILABLE_EXCEL]: handleAvailableExcel,
});

export function setWallLandmarks(newWallLandmarks) {
    return async (dispatch, _, __) => {
        const loadingName = 'loadingWallLandmarksData';
        const keyState = 'wallLandmarksData'
        dispatch({
            type: WALL_AVAILABLE,
            payload: {
                keyState: keyState,
                data: newWallLandmarks,
                loadingName: loadingName
            },
        });
    }
}

export function getWallsAisles(store, supplier_id, alerts_info, date, floorNumber = 1, clientId, signal) {
    return async (dispatch, getState, { services: { dataSource } }) => {
        const loadingName = 'loadingAisles';
        const keyState = 'aislesData';

        // Cancel previous request if exists
        const { currentAislesAbortController } = getState().wall;
        if (currentAislesAbortController) {
            currentAislesAbortController.abort();
        }

        // Use the provided signal or create a new AbortController
        const abortController = signal ? { signal } : new AbortController();

        if (!signal) {
            dispatch({
                type: WALL_AVAILABLE,
                payload: {
                    keyState: 'currentAislesAbortController',
                    data: abortController,
                },
            });
        }

        dispatch({ type: WALL_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getWallsAisles(
                store,
                supplier_id,
                alerts_info,
                date,
                floorNumber,
                clientId,
                signal || abortController.signal
            );
            let aislesData = response.aisles;
            if (aislesData === undefined) {
                alert('Sorry, there are no aisles available at this store');
            }
            // Only update state if this request wasn't aborted
            if (!signal && !abortController.signal.aborted) {
                dispatch({
                    type: WALL_AVAILABLE,
                    payload: {
                        keyState: keyState,
                        data: aislesData,
                        loadingName: loadingName
                    },
                });
            }
        } catch (error) {
            // Only handle error if it's not an abort error
            if (error.name !== 'AbortError') {
                console.log('error: ', error);
                dispatch({
                    type: WALL_UNAVAILABLE,
                    payload: { error, keyState: keyState, loadingName: loadingName },
                });
                const snack = {
                    open: true,
                    message: 'There was an error',
                    severity: 'error',
                };
                dispatch({ type: SNACK_SET, payload: { snack } });
            }
        } finally {
            // Clear the abort controller reference if this was the last request and we created the controller
            if (!signal) {
                const currentController = getState().wall.currentAislesAbortController;
                if (currentController === abortController) {
                    dispatch({
                        type: WALL_AVAILABLE,
                        payload: {
                            keyState: 'currentAislesAbortController',
                            data: null,
                        },
                    });
                }
            }
        }
    };
}

export function getWallImage(store, supplier_id, session, aisle, lang) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingWallImageData';
        const keyState = 'wallImageData'
        dispatch({ type: WALL_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getWallImage(store, supplier_id, session, aisle, lang);
            dispatch({
                type: WALL_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: response,
                    loadingName: loadingName
                },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: WALL_UNAVAILABLE,
                payload: { error, keyState: keyState, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getLandmarks(store, aisle_code, session) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingWallLandmarksData';
        const keyState = 'wallLandmarksData'
        dispatch({ type: WALL_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getLandmarks(store, aisle_code, session);
            dispatch({
                type: WALL_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: response,
                    loadingName: loadingName
                },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: WALL_UNAVAILABLE,
                payload: { error, keyState: keyState, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getAllLandmark(store, supplier_id, session) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingLandmarkData';
        const keyState = 'landmarkData'
        dispatch({ type: WALL_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getLandmark(store, supplier_id, session);
            dispatch({
                type: WALL_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: response,
                    loadingName: loadingName
                },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: WALL_UNAVAILABLE,
                payload: { error, keyState: keyState, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getCoords(store_id, supplier_id, session, aisle, client_id, lang, supplier_info) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingCoordsInfo';
        const keyState = 'coordsInfo'
        dispatch({ type: WALL_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getCoords(store_id, supplier_id, session, aisle, lang, client_id, supplier_info);
            dispatch({
                type: WALL_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: response,
                    loadingName: loadingName
                },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: WALL_UNAVAILABLE,
                payload: { error, keyState: keyState, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getCoordsForExcel(store_id, supplier_id, session, date, lang, supplier_info) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingCoordsForExcel';
        const keyState = 'coordsForExcel'
        dispatch({ type: WALL_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getCoordsForExcel(store_id, supplier_id, session, date, lang, supplier_info);
            dispatch({
                type: WALL_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: response,
                    loadingName: loadingName
                },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: WALL_UNAVAILABLE,
                payload: { error, keyState: keyState, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getMisplacedProductSignedImages(store, blobs) {
    const loadingName = 'loadingMisplacedSignedImages';
    const keyState = 'misplacedSignedImages'

    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: WALL_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getMisplacedProductSignedImages(store, blobs);
            dispatch({
                type: WALL_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: response,
                    loadingName: loadingName
                },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: WALL_UNAVAILABLE,
                payload: { error, keyState: keyState, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getSearchResults(store, supplier_id, aisles, product, days, limit) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingSearchResults';
        const keyState = 'searchResults'
        dispatch({ type: WALL_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getSearchResults(store, supplier_id, product, days, limit);
            // filtering by current aisle
            const filteredResults = response?.results?.filter((result) => aisles.find(aisle => aisle === result.aisle));
            dispatch({
                type: WALL_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: filteredResults,
                    loadingName: loadingName
                },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: WALL_UNAVAILABLE,
                payload: { error, keyState: keyState, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    }
}

export function setProductState(product) {
    return (dispatch) => {
        dispatch({
            type: WALL_AVAILABLE,
            payload: {
                keyState: 'selectedProduct',
                data: product,
            },
        });
    };
}

function handleAvailableExcel(state, { payload: { loadingName, excelData, excelFileName, canFormatData = false } }) {
    const newState = {
        ...state,
        [loadingName]: false,
    };

    const excelExporter = new ExcelExporter();
    excelExporter.exportAsExcelFile({ excelData, excelFileName, canFormatData })

    return newState
}


export function getExcelLandmarks(store, supplier_id) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingLandmarksExcel';
        const keyState = 'excelLandmarks'
        dispatch({ type: WALL_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getLandmarksExcel(store, supplier_id);
            dispatch({
                type: WALL_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: response,
                    loadingName: loadingName
                },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: WALL_UNAVAILABLE,
                payload: { error, keyState: keyState, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getDigitalShelfExcel({ excelData, excelFileName }) {
    return async (dispatch, _, { services: { __ } }) => {
        const loadingName = 'loadingLandmarksExcel'
        const keyState = 'excelFileMessage'

        dispatch({ type: WALL_REQUEST, payload: { loadingName } });
        try {
            dispatch({
                type: DIGITAL_SHELF_AVAILABLE_EXCEL,
                payload: { loadingName, excelData, excelFileName },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: WALL_UNAVAILABLE,
                payload: { error, keyState, loadingName, defaultState: { 'message': 'error' } },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function clearCoordsState() {
    return (dispatch) => {
        dispatch({
            type: WALL_AVAILABLE,
            payload: {
                keyState: 'coordsInfo',
                data: {},
            },
        });
    };
}

export function clearWallImageData() {
    return (dispatch) => {
        dispatch({
            type: WALL_AVAILABLE,
            payload: {
                keyState: 'wallImageData',
                data: {},
            },
        });
    };
}

export function getAislePromotions(store, supplier_id, aisle_code, session, client_id) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingAislePromotions';
        const keyState = 'aislePromotions'
        dispatch({ type: WALL_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getAislePromotions(store, supplier_id, aisle_code, session, client_id);
            dispatch({
                type: WALL_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: response,
                    loadingName: loadingName
                },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: WALL_UNAVAILABLE,
                payload: { error, keyState: keyState, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function setAisleLandmarkState(l) {
    return (dispatch) => {
        dispatch({
            type: WALL_AVAILABLE,
            payload: {
                keyState: 'aisleLandmarks',
                data: l,
            },
        });
    };
}

export function getScannedBaysInfo(store, session, aisle) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingScannedBays';
        const keyState = 'scannedBays'
        dispatch({ type: WALL_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getScannedBays(store, session, aisle);
            dispatch({
                type: WALL_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: response,
                    loadingName: loadingName
                },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: WALL_UNAVAILABLE,
                payload: { error, keyState: keyState, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

