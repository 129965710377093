import { Suspense, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, LinearProgress, Typography } from '@mui/material';


import { DigitalShelfContext } from '../../../contexts/DigitalShelf';
import useAnimationCanvas from '../../tools/useAnimationCanvas';
import { pointToBox } from '../../utils/converters';

export default function WallDrawer(props) {
	const {
		landmark,
		png,
		coords = [],
		wallLandmarks,
		canvasHeight,
		canvasWidth,
		originalWidth,
		originalHeight,
		colors,
		selectedStatus,
		selectedCategories,
		handleCanvasHover,
		handleCanvasLeave = drw => { },
		handleCanvasClick = (e, drw) => { },
		aislePromotions,
		aisleLandmarks,
		imgsDict,
		scannedBays,
		selectedElements,
		handleCoordAlertInfo,
		getCoordAlertName,
		isHoverOn,
		inputZoom,
	} = props;

	const { t } = useTranslation();

	// Context
	const { selectedLandmark } = useContext(DigitalShelfContext);
	const BADGE_RADIUS = 55;

	const [landmarkLabels, setlandmarkLabels] = useState([]);
	const [localCanvasHeight, setLocalCanvasHeight] = useState(canvasHeight);
	const [localCanvasWidth, setLocalCanvasWidth] = useState(canvasWidth);
	const [scaleX, setScaleX] = useState(canvasWidth / originalWidth);
	const [scaleY, setScaleY] = useState(canvasHeight / originalHeight);
	const [wallBugs, setWallBugs] = useState([]);

	const [svgs, setSvgs] = useState(null);
	const [canvasRef, drawerReturn] = useAnimationCanvas();
	const [backgroundCanvasRef] = useAnimationCanvas();
	const [hoverCanvasRef] = useAnimationCanvas();
	const [allHoverAreas, setAllHoverAreas] = useState([]);
	const [mousePosition, setMousePosition] = useState({ x: -100, y: -100 });
	const [infoBoxData, setInfoBoxData] = useState(null);
	const [showInfoBox, setShowInfoBox] = useState(false);

	// Add new state for background image
	const [backgroundImage, setBackgroundImage] = useState(null);

	// Change zoom on wheel event
	const minZoom = 40;
	const maxZoom = 190;
	const [currentZoom, setCurrentZoom] = useState(40);

	// Add these new state variables near the other useState declarations
	const [isPanning, setIsPanning] = useState(false);
	const [startPanPosition, setStartPanPosition] = useState({ x: 0, y: 0 });

	// Estado para manejar el mensaje de hover
	const [hoverInfo, setHoverInfo] = useState(null);
	const [xScrollScale, setXScrollScale] = useState(1);
	const [yScrollScale, setYScrollScale] = useState(1);

	// Add new state to track image loading
	const [isImageLoaded, setIsImageLoaded] = useState(false);

	useEffect(() => {
		if (inputZoom) {
			setCurrentZoom(inputZoom);
		}
	}, [inputZoom]);

	useEffect(() => {
		if (selectedLandmark?.display_name) {
			setCurrentZoom(40);
			setShowInfoBox(false);
		}
	}, [selectedLandmark]);

	useEffect(() => {
		if (!png) return;

		setIsImageLoaded(false); // Reset on new image
		const image = new Image();
		image.src = png;
		image.onload = () => {
			setBackgroundImage(image);
			setLocalCanvasWidth(image.naturalWidth);
			setLocalCanvasHeight(image.naturalHeight);
			setScaleX(image.naturalWidth / originalWidth);
			setScaleY(image.naturalHeight / originalHeight);
			setIsImageLoaded(true);

			// Draw the background only once
			const bgCtx = backgroundCanvasRef.current?.getContext('2d');
			if (bgCtx) {
				backgroundCanvasRef.current.width = image.naturalWidth;
				backgroundCanvasRef.current.height = image.naturalHeight;
				bgCtx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);
			}

			// Scroll to bottom after image loads
			const container = document.getElementById('container');
			if (container) {
				setTimeout(() => {
					container.scrollTop = container.scrollHeight;
				}, 100);
			}
		};
	}, [png, originalWidth, originalHeight]);

	useEffect(() => {
		const wallBugs =
			coords && coords.length
				? [...coords, ...aislePromotions, ...aisleLandmarks]
				: [...aislePromotions, ...aisleLandmarks];
		const filteredWallBugs = wallBugs.filter(element =>
			(selectedStatus || {}).has(getCoordAlertName(landmark, element)),
		);
		setWallBugs(filteredWallBugs);
	}, [
		getCoordAlertName,
		wallLandmarks,
		selectedStatus,
		aislePromotions,
		aisleLandmarks,
		landmark,
		isImageLoaded
	]);

	useEffect(() => {
		const initializeLandmarks = () => {
			if (!wallLandmarks || !Array.isArray(wallLandmarks) || !coords || !Array.isArray(coords)) {
				return;
			}

			let landmarksObj = {};

			// Initialize landmarks first
			wallLandmarks.forEach(l => {
				if (l && l.landmark) {
					landmarksObj[l.landmark] = 0;
				}
			});

			// Then count coords
			coords.forEach(coord => {
				if (coord && coord.landmark && landmarksObj.hasOwnProperty(coord.landmark)) {
					landmarksObj[coord.landmark]++;
				}
			});

			setlandmarkLabels(landmarksObj);
		};

		// Use RAF instead of setTimeout for better performance
		requestAnimationFrame(initializeLandmarks);

	}, [wallLandmarks, coords]); // Remove JSON.stringify to prevent unnecessary re-renders

	useEffect(() => {
		if (imgsDict) {
			setSvgs(imgsDict);
		}
	}, [imgsDict]);

	// Separate background drawing into its own function
	const drawBackground = ctx => {
		if (!backgroundImage) return;
		ctx.clearRect(0, 0, localCanvasWidth, localCanvasHeight);
		ctx.drawImage(backgroundImage, 0, 0, localCanvasWidth, localCanvasHeight);
	};

	// Utility function to apply and reset shadows
	const handleShadow = (ctx, shadow, shadowColor) => {
		const shadowConfigs = {
			sm: { blur: 4, offset: 2 },
			md: { blur: 8, offset: 4 },
			lg: { blur: 12, offset: 6 },
			xl: { blur: 16, offset: 8 },
		};

		if (shadow && shadowConfigs[shadow]) {
			ctx.shadowColor = shadowColor;
			ctx.shadowBlur = shadowConfigs[shadow].blur;
			ctx.shadowOffsetX = shadowConfigs[shadow].offset;
			ctx.shadowOffsetY = shadowConfigs[shadow].offset;
		}

		return () => {
			ctx.shadowColor = 'transparent';
			ctx.shadowBlur = 0;
			ctx.shadowOffsetX = 0;
			ctx.shadowOffsetY = 0;
		};
	};

	const drawRectangleOnCanvas = ({
		ctx,
		p1,
		p2,
		p3,
		p4,
		scale_x,
		scale_y,
		colorFill = 'rgba(255, 164, 0, 0.4)',
		strokeStyle = '#212529',
		lineDash = null,
		lineWidth = 4,
		borderRadius = 3,
		shadow = 'md',
		shadowColor = 'rgba(0, 0, 0, 0.3)',
	}) => {
		ctx.beginPath();
		ctx.fillStyle = colorFill;

		const x1 = p1[0] * scale_x;
		const y1 = p1[1] * scale_y;
		const x2 = p2[0] * scale_x;
		const y2 = p2[1] * scale_y;
		const x3 = p3[0] * scale_x;
		const y3 = p3[1] * scale_y;
		const x4 = p4[0] * scale_x;
		const y4 = p4[1] * scale_y;

		const resetShadow = handleShadow(ctx, shadow, shadowColor);

		// Start from top-left and draw clockwise
		ctx.moveTo(x1 + borderRadius, y1);

		// Top edge
		ctx.lineTo(x2 - borderRadius, y2);
		ctx.quadraticCurveTo(x2, y2, x2, y2 + borderRadius);

		// Right edge
		ctx.lineTo(x3, y3 - borderRadius);
		ctx.quadraticCurveTo(x3, y3, x3 - borderRadius, y3);

		// Bottom edge
		ctx.lineTo(x4 + borderRadius, y4);
		ctx.quadraticCurveTo(x4, y4, x4, y4 - borderRadius);

		// Left edge
		ctx.lineTo(x1, y1 + borderRadius);
		ctx.quadraticCurveTo(x1, y1, x1 + borderRadius, y1);

		ctx.closePath();

		ctx.fillStyle = colorFill;
		ctx.strokeStyle = strokeStyle;
		ctx.lineWidth = lineWidth;
		if (lineDash) ctx.setLineDash(lineDash);
		ctx.stroke();
		ctx.fill();

		resetShadow();
	};

	const drawTargetRectangle = ({
		ctx,
		p1,
		p2,
		p3,
		p4,
		scale_x,
		scale_y,
		strokeStyle = '#212529',
		lineWidth = 4,
		cornerLength = 0.2,
		borderRadius = 7,
		shadow = null,
		shadowColor = 'rgba(255, 255, 255, 0.2)',
	}) => {
		const x1 = p1[0] * scale_x;
		const y1 = p1[1] * scale_y;
		const x2 = p2[0] * scale_x;
		const y3 = p3[1] * scale_y;

		const width = x2 - x1;
		const height = y3 - y1;
		const cornerX = width * cornerLength;
		const cornerY = height * cornerLength;

		const resetShadow = handleShadow(ctx, shadow, shadowColor);

		ctx.beginPath();
		ctx.strokeStyle = strokeStyle;
		ctx.lineWidth = lineWidth;

		// Top left corner
		ctx.moveTo(x1 + cornerX, y1);
		ctx.lineTo(x1 + borderRadius, y1);
		ctx.arc(
			x1 + borderRadius,
			y1 + borderRadius,
			borderRadius,
			Math.PI * 1.5,
			Math.PI,
			true,
		);
		ctx.lineTo(x1, y1 + cornerY);

		// Top right corner
		ctx.moveTo(x2 - cornerX, y1);
		ctx.lineTo(x2 - borderRadius, y1);
		ctx.quadraticCurveTo(x2, y1, x2, y1 + borderRadius);
		ctx.lineTo(x2, y1 + cornerY);

		// Bottom right corner
		ctx.moveTo(x2, y3 - cornerY);
		ctx.lineTo(x2, y3 - borderRadius);
		ctx.quadraticCurveTo(x2, y3, x2 - borderRadius, y3);
		ctx.lineTo(x2 - cornerX, y3);

		// Bottom left corner
		ctx.moveTo(x1 + cornerX, y3);
		ctx.lineTo(x1 + borderRadius, y3);
		ctx.quadraticCurveTo(x1, y3, x1, y3 - borderRadius);
		ctx.lineTo(x1, y3 - cornerY);

		ctx.stroke();

		resetShadow();
	};

	const drawCircleOnCanvas = ({
		ctx,
		centerX,
		centerY,
		radius,
		scale_x,
		scale_y,
		colorFill = 'rgba(255, 164, 0, 0.4)',
		strokeStyle = '#212529',
		lineDash = null,
		lineWidth = 4,
	}) => {
		ctx.beginPath();

		// Scale the center coordinates and radius
		const scaledX = centerX * scale_x;
		const scaledY = centerY * scale_y;
		const scaledRadius = radius * Math.min(scale_x, scale_y);

		ctx.arc(scaledX, scaledY, scaledRadius, 0, 2 * Math.PI);

		ctx.fillStyle = colorFill;
		ctx.strokeStyle = strokeStyle;
		ctx.lineWidth = lineWidth;
		if (lineDash) ctx.setLineDash(lineDash);

		ctx.stroke();
		ctx.fill();
		ctx.closePath();
	};

	const drawGridRectangle = ({
		ctx,
		p1,
		p2,
		p3,
		p4,
		scale_x,
		scale_y,
		grids = [],
		lineWidth = 4,
		borderRadius = 0,
	}) => {
		// Calculate scaled points
		const x1 = p1[0] * scale_x;
		const y1 = p1[1] * scale_y;
		const x2 = p2[0] * scale_x;
		const y3 = p3[1] * scale_y;

		// Calculate midpoints
		const midX = (x1 + x2) / 2;
		const midY = (y1 + y3) / 2;

		// Draw based on number of grids
		switch (grids.length) {
			case 1:
				// Single rectangle
				drawRectangleOnCanvas({
					ctx,
					p1: [p1[0], p1[1]],
					p2: [p2[0], p2[1]],
					p3: [p3[0], p3[1]],
					p4: [p4[0], p4[1]],
					scale_x,
					scale_y,
					colorFill: grids[0].colorFill,
					strokeStyle: grids[0].strokeStyle,
					lineWidth,
					borderRadius,
				});
				break;

			case 2:
				// Two vertical rectangles
				drawRectangleOnCanvas({
					ctx,
					p1: [p1[0], p1[1]],
					p2: [midX / scale_x, p1[1]],
					p3: [midX / scale_x, p3[1]],
					p4: [p4[0], p4[1]],
					scale_x,
					scale_y,
					colorFill: grids[0].colorFill,
					strokeStyle: grids[0].strokeStyle,
					lineWidth,
					borderRadius,
				});
				drawRectangleOnCanvas({
					ctx,
					p1: [midX / scale_x, p1[1]],
					p2: [p2[0], p2[1]],
					p3: [p3[0], p3[1]],
					p4: [midX / scale_x, p4[1]],
					scale_x,
					scale_y,
					colorFill: grids[1].colorFill,
					strokeStyle: grids[1].strokeStyle,
					lineWidth,
					borderRadius,
				});
				break;

			case 3:
				// Two rectangles on top, one on bottom
				drawRectangleOnCanvas({
					ctx,
					p1: [p1[0], p1[1]],
					p2: [midX / scale_x, p1[1]],
					p3: [midX / scale_x, midY / scale_y],
					p4: [p1[0], midY / scale_y],
					scale_x,
					scale_y,
					colorFill: grids[0].colorFill,
					strokeStyle: grids[0].strokeStyle,
					lineWidth,
					borderRadius,
				});
				drawRectangleOnCanvas({
					ctx,
					p1: [midX / scale_x, p1[1]],
					p2: [p2[0], p2[1]],
					p3: [p2[0], midY / scale_y],
					p4: [midX / scale_x, midY / scale_y],
					scale_x,
					scale_y,
					colorFill: grids[1].colorFill,
					strokeStyle: grids[1].strokeStyle,
					lineWidth,
					borderRadius,
				});
				drawRectangleOnCanvas({
					ctx,
					p1: [p1[0], midY / scale_y],
					p2: [p2[0], midY / scale_y],
					p3: [p3[0], p3[1]],
					p4: [p4[0], p4[1]],
					scale_x,
					scale_y,
					colorFill: grids[2].colorFill,
					strokeStyle: grids[2].strokeStyle,
					lineWidth,
					borderRadius,
				});
				break;

			case 4:
				// Four equal rectangles
				drawRectangleOnCanvas({
					ctx,
					p1: [p1[0], p1[1]],
					p2: [midX / scale_x, p1[1]],
					p3: [midX / scale_x, midY / scale_y],
					p4: [p1[0], midY / scale_y],
					scale_x,
					scale_y,
					colorFill: grids[0].colorFill,
					strokeStyle: grids[0].strokeStyle,
					lineWidth,
					borderRadius,
				});
				drawRectangleOnCanvas({
					ctx,
					p1: [midX / scale_x, p1[1]],
					p2: [p2[0], p2[1]],
					p3: [p2[0], midY / scale_y],
					p4: [midX / scale_x, midY / scale_y],
					scale_x,
					scale_y,
					colorFill: grids[1].colorFill,
					strokeStyle: grids[1].strokeStyle,
					lineWidth,
					borderRadius,
				});
				drawRectangleOnCanvas({
					ctx,
					p1: [p1[0], midY / scale_y],
					p2: [midX / scale_x, midY / scale_y],
					p3: [midX / scale_x, p3[1]],
					p4: [p4[0], p4[1]],
					scale_x,
					scale_y,
					colorFill: grids[2].colorFill,
					strokeStyle: grids[2].strokeStyle,
					lineWidth,
					borderRadius,
				});
				drawRectangleOnCanvas({
					ctx,
					p1: [midX / scale_x, midY / scale_y],
					p2: [p2[0], midY / scale_y],
					p3: [p3[0], p3[1]],
					p4: [midX / scale_x, p4[1]],
					scale_x,
					scale_y,
					colorFill: grids[3].colorFill,
					strokeStyle: grids[3].strokeStyle,
					lineWidth,
					borderRadius,
				});
				break;

			default:
				// If invalid number of grids, draw single rectangle with default style
				drawRectangleOnCanvas({
					ctx,
					p1: [p1[0], p1[1]],
					p2: [p2[0], p2[1]],
					p3: [p3[0], p3[1]],
					p4: [p4[0], p4[1]],
					scale_x,
					scale_y,
					colorFill: 'rgba(200, 200, 200, 0.4)', // default gray color
					strokeStyle: '#666666',
					lineWidth,
					borderRadius,
				});
				console.warn(
					`Invalid number of grids provided: ${grids.length}. Expected 1-4 grids.`,
				);
				break;
		}
	};

	// new function draw landmarks
	const drawLandmarksBays = (ctx, scale_x, scale_y, mouseX, mouseY) => {
		const hoverAreas = [];
		if (scannedBays && scannedBays.length) {
			const selectedLandmarkName = selectedLandmark?.display_name;
			const selectedBay = scannedBays.find(
				element => element.display_name === selectedLandmarkName,
			);
			if (selectedBay) {
				if (selectedBay.hasOwnProperty('wall_bbox')) {
					let [p1, p2, p3, p4] = selectedBay.wall_bbox;
					drawRectangleOnCanvas({
						ctx,
						p1,
						p2,
						p3,
						p4,
						scale_x,
						scale_y,
						lineDash: [15, 15],
						lineWidth: 15,
						strokeStyle: '#1e8bea',
						colorFill: '#00000022',
					});
					// Coords
					const x1 = p1[0] * scale_x;
					const y1 = p1[1] * scale_y;
					const x2 = p2[0] * scale_x;

					// draw badge
					const badgeX = x2;
					const badgeY = y1 - BADGE_RADIUS - 5;

					ctx.beginPath();
					ctx.fillStyle = '#298ce3'; // badge color
					ctx.arc(badgeX, badgeY, BADGE_RADIUS, 0, 2 * Math.PI);
					ctx.fill();

					// Add dashed border
					ctx.setLineDash([10, 10]); // Pattern: 4px line, 2px gap
					ctx.lineWidth = 10;
					ctx.strokeStyle = '#1e8bea';
					ctx.stroke();
					ctx.setLineDash([]); // Reset to solid lines

					// text badge
					const detectedProducts = selectedBay?.bayInfo.total;
					ctx.fillStyle = '#fff';
					ctx.font = 'bold 56px Arial';
					ctx.textAlign = 'center';
					ctx.textBaseline = 'middle';
					ctx.fillText(detectedProducts, badgeX, badgeY);

					// saves badge coordinates for hover detection
					hoverAreas.push({
						x: badgeX - BADGE_RADIUS,
						y: badgeY - BADGE_RADIUS,
						width: BADGE_RADIUS,
						height: BADGE_RADIUS,
						xLandmark: x1,
						yLandmark: y1,
						info: {
							standardName: selectedBay.display_name,
							productsDetected: detectedProducts,
						},
					});
				}
				setAllHoverAreas(hoverAreas);
			}
		}
	};

	// Modify drawFunc to only draw if image is loaded
	const drawFunc = () => {
		if (!canvasRef.current || !isImageLoaded) return;

		const ctx = canvasRef.current.getContext('2d');
		ctx.clearRect(0, 0, localCanvasWidth, localCanvasHeight);

		drawBackground(ctx);
		if (wallBugs?.length) {
			wallBugs.forEach(element => {
				if (element.isClicked) return;
				const coordAlertName = getCoordAlertName(landmark, element);
				const coordColors = handleCoordAlertInfo(element).map(color => ({
					colorFill: color.colorInfo.color,
					strokeStyle: color.colorInfo.color,
				}));

				if (selectedStatus && !selectedStatus.has(coordAlertName)) return;
				if (selectedCategories && selectedCategories.has(element.name_category))
					return;

				drawerReturn(drawSqExpanded, [element, scaleX, scaleY, coordColors]);
			});
		}
		// Draw Landmarks texts & dotted lines
		drawerReturn(drawLandmarksBays, [scaleX, scaleY]);
	};

	// Modify useEffect to handle different cases
	useEffect(() => {
		if (!backgroundImage || !isImageLoaded) return;
		const ctx = canvasRef.current?.getContext('2d');
		if (!ctx) return;
		drawFunc();

		let w = document.getElementById('container');
		const scale_x = w.scrollWidth / originalWidth;
		const scale_y = w.scrollHeight / originalHeight;
		setXScrollScale(scale_x);
		setYScrollScale(scale_y);
	}, [
		canvasRef.current,
		localCanvasHeight,
		localCanvasWidth,
		backgroundImage,
		wallBugs,
		landmark,
		isImageLoaded
	]);

	// New function to handle hover drawings
	const drawHovers = () => {
		if (!hoverCanvasRef.current || !selectedElements.length) return;

		const ctx = hoverCanvasRef.current.getContext('2d');
		ctx.clearRect(0, 0, localCanvasWidth, localCanvasHeight);

		// Begin a single path for all elements
		ctx.beginPath();

		// Prepare arrays to batch similar operations
		const targetRectangles = [];
		const circles = [];

		// Collect all drawing operations
		selectedElements.forEach(element => {
			const coordColor = getCoordAlertName(landmark, element);
			const colorFill =
				colors[coordColor]?.focusedColor ?? 'rgba(200, 200, 200, 0.4)';
			const strokeStyle = colors[coordColor]?.focusedColor ?? '#000';

			let coords;
			if (element.hasOwnProperty('pixel_z')) {
				const radius = 40;
				coords = pointToBox({
					pixel_x: element.pixel_x,
					pixel_z: element.pixel_z,
					radius,
				});
			} else {
				coords = {
					x1: element.facing_top_left_x,
					y1: element.facing_top_left_y,
					x2: element.facing_bottom_right_x,
					y2: element.facing_bottom_right_y,
				};
			}

			// Store rectangle data
			targetRectangles.push({
				points: [
					[coords.x1, coords.y1],
					[coords.x2, coords.y1],
					[coords.x2, coords.y2],
					[coords.x1, coords.y2],
				],
				style: {
					strokeStyle: '#FFF',
					lineWidth: 8,
					shadow: 'md',
				},
			});

			// Store circle data
			circles.push({
				center: {
					x: coords.x2 - (coords.x2 - coords.x1) / 2,
					y: coords.y2 - (coords.y2 - coords.y1) / 2,
				},
				radius: 20,
				style: {
					colorFill,
					strokeStyle,
					lineWidth: 4,
				},
			});
		});

		// Batch draw all target rectangles
		ctx.save();
		targetRectangles.forEach(rect => {
			drawTargetRectangle({
				ctx,
				p1: rect.points[0],
				p2: rect.points[1],
				p3: rect.points[2],
				p4: rect.points[3],
				scale_x: scaleX,
				scale_y: scaleY,
				...rect.style,
			});
		});
		ctx.restore();

		// Batch draw all circles
		ctx.save();
		circles.forEach(circle => {
			drawCircleOnCanvas({
				ctx,
				centerX: circle.center.x,
				centerY: circle.center.y,
				radius: circle.radius,
				scale_x: scaleX,
				scale_y: scaleY,
				...circle.style,
			});
		});
		ctx.restore();
	};

	// Update hover effect handling
	useEffect(() => {
		// draw the highlighted elements
		drawHovers();
		// redraw the canvas to withdraw the square in the middle of the bbox if the element is clicked
		drawFunc();
	}, [selectedElements]);

	const drawSqExpanded = (ctx, element, scale_x, scale_y, coordColors) => {
		let max_tl_x;
		let max_tl_y;
		let max_br_x;
		let max_br_y;
		let borderRadius = 5;
		const radius = 25;
		if (element.hasOwnProperty('pixel_z')) {
			borderRadius = 2;
			({
				x1: max_tl_x,
				y1: max_tl_y,
				x2: max_br_x,
				y2: max_br_y,
			} = pointToBox({
				pixel_x: element.pixel_x,
				pixel_z: element.pixel_z,
				radius,
			}));
		} else {
			max_tl_x = element['facing_top_left_x'];
			max_tl_y = element['facing_top_left_y'];
			max_br_x = element['facing_bottom_right_x'];
			max_br_y = element['facing_bottom_right_y'];

			const center_x = (max_tl_x + max_br_x) / 2;
			const center_y = (max_tl_y + max_br_y) / 2;
			({
				x1: max_tl_x,
				y1: max_tl_y,
				x2: max_br_x,
				y2: max_br_y,
			} = pointToBox({ pixel_x: center_x, pixel_z: center_y, radius }));
		}
		// Ignore draw a square in the middle of the bbox if the element is clicked
		if (!element.isClicked) {
			drawGridRectangle({
				ctx,
				p1: [max_tl_x, max_tl_y],
				p2: [max_br_x, max_tl_y],
				p3: [max_br_x, max_br_y],
				p4: [max_tl_x, max_br_y],
				scale_x,
				scale_y,
				lineWidth: 7,
				borderRadius,
				grids: coordColors,
			});
		}
	};

	// redraw the canvas with the current mouse position
	useEffect(() => {
		const canvas = canvasRef.current;
		if (!canvas) return;

		const ctx = canvas.getContext('2d');
		ctx.clearRect(0, 0, localCanvasWidth, localCanvasHeight); // clear the canvas before redrawing
		// draw the highlighted elements
		drawHovers();
		// redraw the canvas to withdraw the square in the middle of the bbox if the element is clicked
		drawFunc();
	}, [mousePosition, scaleX, scaleY, scannedBays]);

	const scaleMousePosition = (canvas, evt) => {
		const rect = canvas.getBoundingClientRect(); // Abs. size of element
		const scaleX = canvas.width / rect.width; // Relationship bitmap vs. element for x
		const scaleY = canvas.height / rect.height; // Relationship bitmap vs. element for y

		return {
			x: (evt.clientX - rect.left) * scaleX, // Scale mouse coordinates after they have
			y: (evt.clientY - rect.top) * scaleY, // been adjusted to be relative to element
		};
	};

	const modifyZoomOnWheel = event => {
		event.preventDefault();
		const canvas = canvasRef.current;
		const bounding = canvas.getBoundingClientRect();

		let newZoom = currentZoom * (event.deltaY * -0.001 + 1);
		newZoom = Math.max(minZoom, Math.min(maxZoom, newZoom));

		const ratio = newZoom / currentZoom;
		setCurrentZoom(newZoom);
		const container = document.getElementById('container');
		canvas.resized_width = bounding.width;
		canvas.resized_height = bounding.height;

		//this maintains the cursor on the same pixel where it was before zooming
		container.scrollLeft = container.scrollLeft + event.offsetX * (-1 + ratio);
		container.scrollTop = container.scrollTop + event.offsetY * (-1 + ratio);

		let w = document.getElementById('container');
		const scale_x = w.scrollWidth / originalWidth;
		const scale_y = w.scrollHeight / originalHeight;
		setXScrollScale(scale_x);
		setYScrollScale(scale_y);
	};

	const handleClickEvent = event => {
		const canvas = canvasRef.current;
		const ctx = canvas.getContext('2d');
		const rect = canvas.getBoundingClientRect();
		const { x, y } = scaleMousePosition(canvas, event);
		const mouseX =
			(event.clientX - rect.left) * (localCanvasWidth / rect.width);
		const mouseY =
			(event.clientY - rect.top) * (localCanvasHeight / rect.height);

		handleCanvasClick(
			{
				clientX: x,
				clientY: y,
				canvasWidth: canvas.width,
				canvasHeight: canvas.height,
			},
			drawerReturn,
		);
		const scale_x = localCanvasWidth / originalWidth;
		const scale_y = localCanvasHeight / originalHeight;
		// check click
		const clickedBadge = allHoverAreas.find(area => {
			const areaX = area.x * scale_x;
			const areaY = area.y * scale_y;
			const areaWidth = area.width * scale_x;
			const areaHeight = area.height * scale_y;
			return (
				mouseX >= areaX - BADGE_RADIUS &&
				mouseX <= areaX + areaWidth + BADGE_RADIUS &&
				mouseY >= areaY - BADGE_RADIUS &&
				mouseY <= areaY + areaHeight + BADGE_RADIUS
			);
		});
		// if the badge is clicked, we need to show the info box
		if (clickedBadge) {
			setMousePosition({ x: mouseX, y: mouseY });
			setInfoBoxData({
				...clickedBadge.info,
				x: clickedBadge.xLandmark,
				y: clickedBadge.yLandmark,
			});
			ctx.beginPath();
			ctx.fillStyle = '#298ce3'; // badge color
			const textWidth = ctx.measureText(clickedBadge.info.standardName).width;
			ctx.rect(
				clickedBadge.xLandmark,
				clickedBadge.yLandmark - BADGE_RADIUS * 2,
				textWidth,
				BADGE_RADIUS * 2,
			);
			ctx.fill();

			// Add dashed border
			ctx.setLineDash([10, 10]); // Pattern: 4px line, 2px gap
			ctx.lineWidth = 10;
			ctx.strokeStyle = '#1e8bea';
			ctx.stroke();
			ctx.setLineDash([]); // Reset to solid lines

			// text badge
			ctx.fillStyle = '#fff';
			ctx.font = 'bold 56px Arial';
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';
			ctx.fillText(
				clickedBadge.info.standardName,
				clickedBadge.xLandmark + textWidth / 2,
				clickedBadge.yLandmark - BADGE_RADIUS,
			);
		}
	};

	useEffect(() => {
		const canvas = canvasRef.current;
		if (!canvas) return;
		canvas.addEventListener('click', handleClickEvent);

		return () => {
			canvas.removeEventListener('click', handleClickEvent);
		};
	}, [canvasRef, JSON.stringify(allHoverAreas)]);

	useEffect(() => {
		const canvas = canvasRef.current;
		if (!canvas) return;
		const handleMouseMove = event => {
			// Manejo del hover
			const { x, y } = scaleMousePosition(canvas, event);
			handleCanvasHover({ clientX: x, clientY: y }, drawerReturn);

			// Manejo del panning
			if (isPanning) {
				const container = document.getElementById('container');
				container.scrollLeft = startPanPosition.x - event.clientX;
				container.scrollTop = startPanPosition.y - event.clientY;
			}
		};

		const handleMouseDown = event => {
			if (event.button === 2) {
				// Right click
				const container = document.getElementById('container');
				setIsPanning(true);
				setStartPanPosition({
					x: event.clientX + container.scrollLeft,
					y: event.clientY + container.scrollTop,
				});
			}
		};

		const handleMouseUp = () => {
			setIsPanning(false);
		};

		const handleMouseLeave = () => {
			setIsPanning(false);
			handleCanvasLeave(drawerReturn);
		};

		const handleMouseHover = event => {
			const { x, y } = scaleMousePosition(canvas, event);
			handleCanvasHover({ clientX: x, clientY: y });
		};

		// Agregar el manejador para prevenir el menú contextual
		const handleContextMenu = event => {
			event.preventDefault();
		};

		canvas.addEventListener('click', handleClickEvent);
		canvas.addEventListener('mousedown', handleMouseDown);

		canvas.addEventListener('mousemove', handleMouseMove);
		canvas.addEventListener('mouseup', handleMouseUp);

		canvas.addEventListener('mouseleave', handleMouseLeave);
		canvas.addEventListener('wheel', modifyZoomOnWheel);
		canvas.addEventListener('mouseover', handleMouseHover);
		canvas.addEventListener('contextmenu', handleContextMenu);

		return () => {
			canvas.removeEventListener('click', handleClickEvent);
			canvas.removeEventListener('mousedown', handleMouseDown);
			canvas.removeEventListener('mousemove', handleMouseMove);
			canvas.removeEventListener('mouseup', handleMouseUp);
			canvas.removeEventListener('mouseleave', handleMouseLeave);
			canvas.removeEventListener('wheel', modifyZoomOnWheel);
			canvas.removeEventListener('mouseover', handleMouseHover);
			canvas.removeEventListener('contextmenu', handleContextMenu);
		};
	}, [canvasRef, isPanning, startPanPosition, currentZoom, selectedStatus]);

	// Add this useEffect after the other useEffects
	useEffect(() => {
		const container = document.getElementById('container');
		if (container) {
			container.scrollTop = container.scrollHeight;
		}
	}, [backgroundImage]); // We use backgroundImage as dependency to ensure the image is loaded

	const handleMouseMove = (event) => {
		const canvas = canvasRef.current;
		const { x, y } = scaleMousePosition(canvas, event);

		// Ajustar las coordenadas del mouse según el zoom actual
		const mouseX = (event.clientX - canvas.getBoundingClientRect().left) * (canvas.width / canvas.getBoundingClientRect().width);
		const mouseY = (event.clientY - canvas.getBoundingClientRect().top) * (canvas.height / canvas.getBoundingClientRect().height);

		// Detectar si el mouse está sobre un badge
		const hoveredBadge = allHoverAreas.find(area => {
			const areaX = area.x * scaleX;
			const areaY = area.y * scaleY;
			const areaWidth = area.width * scaleX;
			const areaHeight = area.height * scaleY;

			return mouseX >= areaX &&
				mouseX <= areaX + areaWidth &&
				mouseY >= areaY &&
				mouseY <= areaY + areaHeight;
		});

		if (hoveredBadge) {
			setHoverInfo({
				x: hoveredBadge.x,
				y: hoveredBadge.y,
				message: `Información: ${hoveredBadge.info.standardName}`
			});
		} else {
			setHoverInfo(null);
		}
	};

	// Agregar el manejador de mousemove
	useEffect(() => {
		const canvas = canvasRef.current;
		if (!canvas) return;

		canvas.addEventListener('mousemove', handleMouseMove);

		return () => {
			canvas.removeEventListener('mousemove', handleMouseMove);
		};
	}, [canvasRef, allHoverAreas, scaleX, scaleY]);

	return (
		<Box
			id="container"
			sx={{
				position: 'relative',
				width: '100%',
				overflowX: 'scroll',
				overflowY: 'scroll',
				height: '51vh',
			}}>
			<Suspense
				fallback={
					<LinearProgress sx={{ width: '98%', mx: 'auto' }} color="secondary" />
				}>
				<canvas
					id="backgroundCanvas"
					ref={backgroundCanvasRef}
					height={localCanvasHeight}
					width={localCanvasWidth}
					style={{
						width: 'auto',
						height: `${currentZoom}vh`,
						position: 'absolute',
						top: 0,
						left: 0,
						zIndex: 1,
					}}
				/>
				<canvas
					id="canvas"
					ref={canvasRef}
					tabIndex="1"
					height={localCanvasHeight}
					width={localCanvasWidth}
					style={{
						width: 'auto',
						height: `${currentZoom}vh`,
						position: 'absolute',
						top: 0,
						left: 0,
						zIndex: 2,
						touchAction: 'none',
						cursor: isPanning ? 'grabbing' : isHoverOn ? 'pointer' : 'auto',
					}}
				/>
				<canvas
					id="hoverCanvas"
					ref={hoverCanvasRef}
					height={localCanvasHeight}
					width={localCanvasWidth}
					style={{
						width: 'auto',
						height: `${currentZoom}vh`,
						pointerEvents: 'none',
						zIndex: 3,
						position: 'absolute',
						top: 0,
						left: 0,
					}}
				/>
			</Suspense>
			{showInfoBox && (
				<Box
					id="infoBox"
					sx={{
						position: 'absolute',
						minWidth: '5em',
						maxHeight: '5em',
						height: 'min-content',
						color: 'white',
						border: '3px solid #1e8bea',
						top: `calc(${infoBoxData.y}px - 1.7em)`,
						left: `calc(${infoBoxData?.x}px - 0.19em)`,
						background: '#00000077',
						paddingX: 1,
						borderRadius: '7px',
						borderStyle: 'dashed',
					}}>
					<Typography variant="subtitle2">
						{infoBoxData?.standardName}
					</Typography>
				</Box>
			)}
			{hoverInfo && (
				<Box
					sx={{
						position: 'absolute',
						top: `${((hoverInfo?.y + BADGE_RADIUS) * yScrollScale)}px`,
						left: `${((hoverInfo?.x + BADGE_RADIUS) * xScrollScale)}px`,
						bgcolor: 'rgba(97, 97, 97, 0.92)',
						color: 'common.white',
						p: 1,
						borderRadius: 1,
						fontSize: '0.875rem',
						maxWidth: 300,
						fontWeight: 'medium',
						lineHeight: '1.43',
						letterSpacing: '0.01071em',
						zIndex: 1500,
					}}>
					<Typography variant="body2">{t('cws_app.Walls.total_labels_detected', 'Total labels detected')}</Typography>
				</Box>
			)}
		</Box>
	);
}
