import { useEffect, useState } from "react";

import {
	Box,
	CircularProgress,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Tooltip,
	Typography,
} from '@mui/material';

import { getConsolidatedTranslations } from 'core/utils/parsers';
import { useTranslation } from "react-i18next";
import { format, getLocalTimeFromUTC } from '../../utils/converters';

import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

import Carousel from 'react-material-ui-carousel';
//import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const iconHUA = {
	'detected_products': 'stock',
	'stock': 'bag-check-outline',
	'stockout': 'stock-out',
	'price_difference': 'price-difference',
	'spread_to_fill': 'grid-outline',
	'missing_label': 'remove-circle-outline',
	'seen_on_day': 'calendar-outline',
	'extra_label': 'price-difference'
}

export default function CustomCardContent(props) {
	const {
		selectedProduct,
		selectedStore,
		dateState,
		missingLabelInfo,
		misplacedSignedImages,
		currencySymbol,
		clientFormats,
		alertsIcons,
	} = props;

	const { t, i18n } = useTranslation();

	const [cardDataHUA, setCardDataHua] = useState([]);
	const [productInfo, setProductInfo] = useState(null);

	useEffect(() => {
		if (missingLabelInfo) {
			setCardDataHua(['detected_products', 'stock', 'stockout', 'price_difference', 'spread_to_fill', 'missing_label', 'extra_label', 'seen_on_day']);
		} else {
			setCardDataHua(['detected_products', 'stock', 'stockout', 'price_difference', 'spread_to_fill', 'seen_on_day']);
		}
	}, [missingLabelInfo])

	// Parametrize the product info
	useEffect(() => {
		if (selectedProduct && selectedProduct?.texts) {
			let master_positions = '';
			if (selectedProduct?.store_info?.master_positions?.length > 1) {
				// Join the theoretical positions into one string for the misplaced label if it comes more than one position
				selectedProduct?.store_info?.master_positions.forEach((location, index) => {
					master_positions += location?.landmark ? location?.landmark + (index < selectedProduct?.store_info?.master_positions.length - 1 ? ', ' : '') : ''
				})
				master_positions = <div>{getConsolidatedTranslations(t, selectedStore?.chain_name, 'landmark', 'Walls', 'Landmark')}: {master_positions}</div>
			} else if (selectedProduct?.store_info?.master_positions?.length === 1) {
				// Pass a react node to the secondary prop of the ListItemText for the misplaced label if it comes only one position
				master_positions = <div>
					<div>{getConsolidatedTranslations(t, selectedStore?.chain_name, 'landmark', 'Walls', 'Landmark')}: {selectedProduct?.store_info?.master_positions[0]?.landmark !== null ? selectedProduct?.store_info?.master_positions[0]?.landmark : '-'}</div>
					<div>{t('cws_app.Walls.level', 'Level')}: {selectedProduct?.store_info?.master_positions[0]?.level !== null ? selectedProduct?.store_info?.master_positions[0]?.level : '-'}</div>
					<div>{t('cws_app.Walls.sequence', 'Sequence')}: {selectedProduct?.store_info?.master_positions[0]?.sequence !== null ? selectedProduct?.store_info?.master_positions[0]?.sequence : '-'}</div>
				</div>
			}
			const seenOnDay = format(dateState, i18n.language, clientFormats?.date_format);

			setProductInfo({
				alerts: { data: selectedProduct.hasOwnProperty('stockout'), icon: 'information-circle', secondary: alertsIcons, primary: t('cws_app.general.status', 'Status') },
				price: { data: selectedProduct?.price > 0, icon: 'pricetag', secondary: currencySymbol + selectedProduct?.price, primary: getConsolidatedTranslations(t, selectedStore?.chain_name, 'price', 'Walls', 'Price') },
				ean: { data: selectedProduct?.ean.toString().length > 1, icon: 'barcode-outline', secondary: selectedProduct?.ean, primary: getConsolidatedTranslations(t, selectedStore?.chain_name, 'ean', 'Walls', 'Barcode') },
				item: { data: selectedProduct?.item.toString().length > 1, icon: 'disc-outline', secondary: selectedProduct?.item, primary: getConsolidatedTranslations(t, selectedStore?.chain_name, 'item', 'Walls', 'SKU') },
				name_category: { data: selectedProduct?.name_category, icon: 'apps-outline', secondary: selectedProduct?.name_category, primary: t('cws_app.general.sel_category') },
				seen_on_day: { data: seenOnDay, icon: 'calendar-outline', secondary: seenOnDay, primary: t('cws_app.Walls.seen_on_day') },
				landmark: { data: selectedProduct?.landmark, icon: 'location', secondary: selectedProduct?.landmark, primary: getConsolidatedTranslations(t, selectedStore?.chain_name, 'landmark', 'Walls', 'Landmark') },
				master_positions: { data: selectedProduct?.store_info?.master_positions?.length > 0, icon: 'location', secondary: master_positions, primary: t('cws_app.Walls.theoretical_positions', 'Theoretical Positions') },
				pos_price: { data: selectedProduct?.pos_price, icon: 'pricetag', secondary: currencySymbol + selectedProduct?.pos_price, primary: t('cws_app.Walls.pos_price', 'POS Price') },
				price_update_timestamp: { data: selectedProduct?.price_update_timestamp, icon: 'calendar-outline', secondary: selectedProduct?.price_update_timestamp ? format(getLocalTimeFromUTC(selectedProduct?.price_update_timestamp).localDate, i18n.language, 'dd/MM/yyyy HH:mm') : '-', primary: t('cws_app.Walls.price_update_timestamp', 'Price Update'), tooltip: Intl.DateTimeFormat().resolvedOptions().timeZone },
				stock_available: { data: selectedProduct?.stock_available, icon: 'bag-check-outline', secondary: selectedProduct?.stock_available, primary: t('cws_app.Walls.stock_available', 'Stock Available') },
				task_stock_available: { data: selectedProduct?.task_stock_available, icon: 'bag-check-outline', secondary: selectedProduct?.task_stock_available, primary: t('cws_app.Walls.task_stock_available', 'Task Stock Available') },
				stock_update_timestamp: { data: selectedProduct?.stock_update_timestamp, icon: 'calendar-outline', secondary: selectedProduct?.stock_update_timestamp ? format(getLocalTimeFromUTC(selectedProduct?.stock_update_timestamp).localDate, i18n.language, 'dd/MM/yyyy HH:mm') : '-', primary: t('cws_app.Walls.stock_update_timestamp', 'Stock Update'), tooltip: Intl.DateTimeFormat().resolvedOptions().timeZone },
			});
		}
	}, [alertsIcons, selectedProduct])

	return (
		<Box sx={{ width: '100%' }}>
			{selectedProduct?.texts ?
				<Grid container alignItems={'center'}>
					<Grid item xs={12} size={12} justifyContent={'center'} alignItems={'center'} display={'flex'}>
						<List dense sx={styles.rootList}>
							<Box sx={[styles.boxList]}>
								{productInfo && Object.keys(productInfo).map((item, idx) => {
									return (
										<>
											{productInfo[item].data ?
												<ListItem
													key={item + '-' + idx}
													sx={styles.listItem}
													secondaryAction={<Tooltip title={productInfo[item].tooltip} arrow><Typography variant='body2' >{productInfo[item].secondary}</Typography></Tooltip>}
												>
													<ListItemAvatar sx={(theme) => { return { ...styles.avatar, color: theme.palette.secondary.main } }}> <IconComponent iconName={productInfo[item].icon} style={{ ...styles.icon, color: 'inherit' }} /> </ListItemAvatar>
													<ListItemText
														primary={<Typography variant='body2' sx={{ fontWeight: 500 }}>{productInfo[item].primary}</Typography>}
													/>
												</ListItem>
												: null
											}
										</>)
								})}
							</Box>
						</List>
					</Grid>
					{selectedProduct && selectedProduct?.misplaced_product ?
						<Grid item container xs justifyContent={'center'} alignItems={'center'}>
							{misplacedSignedImages?.length ?
								<Carousel id='carousel' height={100} sx={{ width: '150px', m: 0, p: 0 }} navButtonsAlwaysInvisible fullHeightHover={false} >
									{misplacedSignedImages.map((item, idx) => {
										return (
											<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
												<img key={idx} src={item} alt='' style={{ maxHeight: 100, maxWidth: 140, borderRadius: '5px' }} />
											</Box>
										)
									})}
								</Carousel>
								:
								<Box sx={{ p: 1, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
									<CircularProgress m={1} />
									<Typography m={1} fontSize={10}>Loading...</Typography>
								</Box>
							}
						</Grid>
						: null
					}
				</Grid>

				: selectedProduct?.data ?
					<Grid container alignItems={'center'}>
						<List dense sx={styles.rootList}>
							<ListItem
								sx={[styles.listItemLandmark, { my: 0 }]}
							>
								<ListItemAvatar sx={(theme) => { return { ...styles.avatar, color: theme.palette.secondary.main } }}> <IconComponent iconName={'location'} style={{ ...styles.icon, color: 'inherit' }} height={'1.3em'} /> </ListItemAvatar>
								<ListItemText
									primary={
										<Typography sx={{ fontWeight: 500 }}>
											{getConsolidatedTranslations(t, selectedStore?.chain_name, 'landmark', 'Walls', 'landmark')
												+ ': ' + t(`cws_app.Walls.${selectedProduct.landmark_type}`, selectedProduct.landmark_type)}
										</Typography>
									}
								/>
							</ListItem>
							<Divider sx={styles.divider} />
							<Box sx={[styles.boxList]}>
								{cardDataHUA.map((item, idx) =>
									<ListItem key={item + '-' + idx} sx={styles.listItem}
										secondaryAction={<Typography>{
											item === 'seen_on_day' ? format(dateState, i18n.language, clientFormats?.date_format)
												: item === 'missing_label' ?
													(missingLabelInfo?.hasOwnProperty(selectedProduct.landmark) ? missingLabelInfo[selectedProduct.landmark] : 0)
													: item === 'detected_products' ?
														selectedProduct?.data['total']
														: selectedProduct?.data[item.toLowerCase()]
										}
										</Typography>
										}
									>
										<ListItemAvatar sx={(theme) => { return { ...styles.avatar, color: theme.palette.secondary.main } }}><IconComponent iconName={iconHUA[item]} style={{ ...styles.icon, color: 'inherit' }} height={'1.2em'} /></ListItemAvatar>
										<ListItemText primary={t('cws_app.Walls.' + item.toLowerCase(), item)} />
									</ListItem>
								)}
							</Box>
						</List>
					</Grid>

					: selectedProduct?.catalog ?
						<Grid container>
							<List dense sx={styles.rootList}>
								<ListItem
									sx={styles.listItem}
									secondaryAction={<Typography>{selectedProduct?.catalog}</Typography>}
								>
									<ListItemAvatar sx={(theme) => { return { ...styles.avatar, color: theme.palette.secondary.main } }}> <IconComponent iconName={"apps-outline"} color="secondary" style={{ ...styles.icon, color: 'inherit' }} height={'1.3em'} /></ListItemAvatar>
									<ListItemText
										primary={getConsolidatedTranslations(t, selectedStore?.chain_name, 'store_catalog', 'Walls', 'store_catalog')}
									/>
								</ListItem>
								<ListItem
									sx={styles.listItem}
									secondaryAction={<Typography>{format(new Date(selectedProduct?.start_date ?? null), i18n.language, clientFormats?.date_format)}</Typography>}
								>
									<ListItemAvatar sx={(theme) => { return { ...styles.avatar, color: theme.palette.secondary.main } }}> <IconComponent iconName={"calendar-outline"} color="secondary" style={{ ...styles.icon, color: 'inherit' }} height={'1.3em'} /> </ListItemAvatar>
									<ListItemText
										primary={t('cws_app.Walls.start_date')}
									/>
								</ListItem>
								<ListItem
									sx={styles.listItem}
									secondaryAction={<Typography>{format(new Date(selectedProduct?.end_date ?? null), i18n.language, clientFormats?.date_format)}</Typography>}
								>
									<ListItemAvatar sx={(theme) => { return { ...styles.avatar, color: theme.palette.secondary.main } }}> <IconComponent iconName={"calendar-outline"} color="secondary" style={{ ...styles.icon, color: 'inherit' }} height={'1.3em'} /> </ListItemAvatar>
									<ListItemText
										primary={t('cws_app.Walls.end_date')}
									/>
								</ListItem>
							</List>
						</Grid>
						: <Grid container>
							<Box sx={{
								width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center',
								alignItems: 'center',
								height: '15em',
								border: '1px solid #c7c7d754',
								borderRadius: '8px',
								alignContent: 'center',
							}}>
								<Typography variant='body1' sx={{ color: '#d3d3d3cc', ...styles.bottomText }}>{t('cws_app.Walls.no_item_selected', "No item selected")}</Typography>
							</Box>
						</Grid>
			}
		</Box >
	)
}


const styles = {
	rootList: {
		minWidth: '90%!important',
		maxHeight: '20em',
		border: '1px solid #0000001f',
		borderTop: '0px',
		overflow: 'auto',
	},
	divider: {
		borderTop: '1px solid #ffffff52',
		borderRadius: 1,
		width: '100%',
	},
	title: {
		fontSize: 10,
		fontWeight: 'bold',
	},
	body: {
		fontSize: 8,
	},
	bottomText: {
		fontSize: '14px',
		mb: 1
	},
	icon: {
		fontSize: 20,
		mx: 1
	},
	listItemLandmark: {
		width: 300,
	},
	listItem: {
		minWidth: '18em',
	},
	boxList: {
		width: '100%!important',
		display: 'flex',
		flexFlow: 'column wrap',
		gap: "0 0px",
		overflow: 'auto',
		my: 0,
	},
	secondaryText: { color: '#ffffffc2' },
	avatar: {
		minWidth: '2em',
	}
}
