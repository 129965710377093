import React, { useContext } from 'react';
import { format, pointToBox } from '../../utils/converters';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS, handleCoordAlertInfo } from '../../utils/colors';
// Icons

import { isEmptyOrUndefined } from "@zippeditoolsjs/blocks";

// My Components
import { getChainMeaning } from 'core/utils/parsers';
import { DigitalShelfContext } from '../../../contexts/DigitalShelf';
import Modal from '../../tools/Modal';
import WallDrawer from './WallDrawer';
import WallMetadata from './WallMetadata';

// constants
const GRID_CELL_SIZE = 25; // Size of each grid cell in pixels

const getCoordAlertName = (landmark, element) => {
  let coordColor = 'stock';
  if (element.hasOwnProperty('stockout')) {
    if (element.stockout) coordColor = 'stockout';
    if (element.spread_to_fill) coordColor = 'spread_to_fill';
    else if (element.price_difference) coordColor = 'price_difference';
    else if (element.extra_label) coordColor = 'extra_label';
    else if (element.misplaced_product) coordColor = 'misplaced_product'
  } else if (!element.landmark_type && element.hasOwnProperty('pixel_z')) {
    coordColor = 'promotion';
  } else if (element.landmark_type) {
    coordColor = 'hua';
  }

  // color selected landmark only (nice to have)
  if (landmark && landmark.display_name && element.landmark
    && element.landmark !== landmark.display_name
    && !element.landmark_type
  ) {
    coordColor = 'invisible';
  } else if (landmark && landmark.display_name && !element.landmark_type && element.hasOwnProperty('pixel_z')) {
    coordColor = 'invisible';
  }


  return coordColor;
}

// Add this new function to create the spatial hash
const createSpatialHash = (coordsData, canvasWidth, canvasHeight, scale_x, scale_y) => {
  const grid = {};

  const getGridCells = (x1, y1, x2, y2) => {
    const startCol = Math.floor(x1 / GRID_CELL_SIZE);
    const endCol = Math.floor(x2 / GRID_CELL_SIZE);
    const startRow = Math.floor(y1 / GRID_CELL_SIZE);
    const endRow = Math.floor(y2 / GRID_CELL_SIZE);

    const cells = [];
    for (let col = startCol; col <= endCol; col++) {
      for (let row = startRow; row <= endRow; row++) {
        cells.push(`${col},${row}`);
      }
    }
    return cells;
  };

  coordsData.forEach((coord, idx) => {
    let x1, y1, x2, y2;

    if (coord.hasOwnProperty('pixel_z')) {
      // For landmark icons
      ({ x1, y1, x2, y2 } = pointToBox({ pixel_x: coord.pixel_x, pixel_z: coord.pixel_z, radius: 32, scale_x, scale_y }));
    } else {
      // For product facings
      x1 = coord.facing_top_left_x * scale_x;
      y1 = coord.facing_top_left_y * scale_y;
      x2 = coord.facing_bottom_right_x * scale_x;
      y2 = coord.facing_bottom_right_y * scale_y;
    }

    const cells = getGridCells(x1, y1, x2, y2);
    cells.forEach(cell => {
      if (!grid[cell]) {
        grid[cell] = [];
      }
      grid[cell].push({
        idx,
        coord,
        bounds: { x1, y1, x2, y2 }
      });
    });
  });

  return grid;
};

export default function Wall(props) {
  const { t, i18n } = useTranslation();
  const {
    selectedClient,
    wallImageData,
    landmarkInfo,
    isLoadingCoordsInfo,
    isLoadingWallImage,
    wallLandmarks,
    getDigitalShelfExcel,
    selectedProductState,
    clearCoordsState,
    clearWallImageData,
    aislePromotions,
    aisleLandmarks,
    misplacedSignedImages: incomingMisplacedSignedImages,
    getMisplacedProductSignedImages,
    getCoordsForExcel,
    loadingCoordsForExcel,
    coordsForExcel,
    imgsDict = null,
    getNumberFormat,
    currencySymbol,
    isLoadingMaps,
    storeMaps,
    clientFormats,
    scannedBaysData
  } = props;

  const wallImage = new Image();

  const [canvasHeight, setCanvasHeight] = useState(500);
  const [canvasWidth, setCanvasWidth] = useState(1000);
  const [originalWidth, setOriginalWidth] = useState(null);
  const [originalHeight, setOriginalHeight] = useState(null);
  const [excelColumns, setExcelColumns] = useState(new Set());
  const [alertPills, setAlertPills] = useState({
    stock: 0,
    stockout: 0,
    spread_to_fill: 0,
    price_difference: 0,
    promotion: 0,
    hua: 0,
    misplaced_product: 0,
    extra_label: 0,
  });
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(
    new Set([
      'stockout',
    ]),
  );
  const [selectedCategories, setSelectedCategories] = useState(new Set());
  const [isHoverOn, setIsHoverOn] = useState(false);
  const [selectedElements, setSelectedElements] = useState([]);
  const selectedProductIndex = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [parsedLandmarks, setParsedLandmarks] = useState([]);
  const [inputZoom, setInputZoom] = useState(null);

  // Used for not showing the tooltip when the user is hovering on it, but the productType is not selected
  const [selectedProductType, setSelectedProductType] = useState(null);
  const [isWallExtend, setIsWallExtend] = useState(true);
  const wallBoxRef = useRef(null);
  const wallViewRef = useRef(null);
  const wallScroll = useRef(null);
  const spatialHashRef = useRef(null);

  // Context
  const {
    dateState,
    selectedStore,
    selectedProduct,
    setWallSignedUrl,
    setSelectedProduct,
    setMisplacedSignedImages,
    setMissingLabelInfo,
    selectedAisle,
    setProductPosPercent,
    selectedLandmark,
    productSearched,
    baysDataWithCounts,
    setBaysDataWithCounts,
  } = useContext(DigitalShelfContext);

  const handleSelectElement = (elements) => {
    setSelectedElements((beforeSelected) => {
      beforeSelected.forEach(element => {
        element.isClicked = false;
      });
      return elements.map(element => {
        element.isClicked = true;
        return element;
      })
    })
  }

  useEffect(() => {
    setIsWallExtend(true);
    handleResize(true);
  }, [productSearched]);

  useEffect(() => {
    if (selectedProductState) {
      const selectedProductCoords = landmarkInfo?.coords?.filter(coord => coord.item === selectedProductState.item && coord.ean === selectedProductState.ean) || [];
      handleSelectElement(selectedProductCoords);
    }
  }, [selectedProductState]);

  useEffect(() => {
    if (wallLandmarks && wallLandmarks.length && canvasWidth) {
      const parsedLandmarks = {}
      wallLandmarks.forEach((landmark, idx) => {
        if (landmark.hasOwnProperty('pixel_x')) {
          const x = (landmark.pixel_x > 0 ? landmark.pixel_x : 4);
          let nextX = 0;
          if (idx < wallLandmarks.length - 1) {
            nextX = wallLandmarks[idx + 1].pixel_x;
          } else {
            nextX = null;
          }
          const endX = x < nextX ? nextX : landmark.next_landmark_position ? landmark.next_landmark_position : 0;
          parsedLandmarks[landmark.landmark] = {
            ...landmark,
            px_init: x,
            px_end: endX,
            landmarkPercentageInit: x / canvasWidth,
            landmarkPercentageEnd: endX / canvasWidth,
            originalWallWidth: canvasWidth,
          };
        }
      });
      setParsedLandmarks(parsedLandmarks);
    };
  }, [wallLandmarks, canvasWidth]);

  useEffect(() => {
    if (incomingMisplacedSignedImages) {
      setMisplacedSignedImages(incomingMisplacedSignedImages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingMisplacedSignedImages]);

  useEffect(() => {
    if (selectedProduct && selectedProduct?.misplaced_product) {
      getMisplacedProductSignedImages(selectedStore.store_code, selectedProduct.blob)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct])

  // recover missing label data
  useEffect(() => {
    setMissingLabelInfo(landmarkInfo?.missing_label_data);
  }, [landmarkInfo]);


  // load img data
  useEffect(() => {
    if (!isLoadingWallImage && Object.keys(wallImageData).length > 0) {
      handleSelectElement([])
      wallImage.src = wallImageData.signed_url;
      wallImage.onload = () => {
        setWallSignedUrl(wallImageData.signed_url);
        let canvas_element = document.getElementById('wallScroll');
        if (canvas_element) {
          setOriginalWidth(wallImage.naturalWidth);
          setOriginalHeight(wallImage.naturalHeight);

          setCanvasWidth(wallImage.naturalWidth);
          setCanvasHeight(wallImage.naturalHeight);
        }
      };
    }
  }, [wallImageData]);

  // alert pills
  useEffect(() => {
    if (
      landmarkInfo &&
      Object.keys(landmarkInfo) &&
      landmarkInfo.coords &&
      landmarkInfo.coords.length
    ) {
      let excelCols = new Set();
      let pills = {
        stock: 0,
        zero_stock: 0,
        stockout: 0,
        spread_to_fill: 0,
        price_difference: 0,
        hua: 0,
        promotion: 0,
        misplaced_product: 0,
        extra_label: 0,
      };
      landmarkInfo.coords.forEach(coord => {
        if (coord.misplaced_product) {
          pills.misplaced_product++;
          excelCols.add('misplaced_product');
        }
        if (coord.stockout) {
          pills.stockout++;
          excelCols.add('stockout');
        }
        if (coord.spread_to_fill) {
          pills.spread_to_fill++;
          excelCols.add('spread_to_fill');
        }
        if (coord.price_difference) {
          pills.price_difference++;
          excelCols.add('price_difference');
        }

        if (coord.extra_label) {
          pills.extra_label++;
          excelCols.add('extra_label');
        } else pills.stock++;
      });
      wallLandmarks.forEach(l => {
        pills.hua++;
      });
      aislePromotions.forEach(l => {
        pills.promotion++;
      });
      setAlertPills(pills);
      setExcelColumns(excelCols);
    }
  }, [landmarkInfo]);

  // category filters
  useEffect(() => {
    if (
      landmarkInfo &&
      Object.keys(landmarkInfo) &&
      landmarkInfo.coords &&
      landmarkInfo.coords.length
    ) {
      let pills = [];
      landmarkInfo.coords.forEach(coord => {
        if (coord.name_category && !pills.includes(coord.name_category)) {
          pills.push(coord.name_category);
        }
      });
      pills.sort((a, b) => {
        if (a > b) return 1;
        return -1;
      });
      setCategoryFilters(pills);
    }
  }, [landmarkInfo]);

  // scroll to landmark
  useEffect(() => {
    if (
      canvasWidth &&
      originalWidth &&
      selectedLandmark &&
      selectedLandmark.pixel_x
    ) {
      // TODO: sync With zoom or click on landmark
      setTimeout(() => {
        let w = document.getElementById('container');
        const scale_x = w.scrollWidth / originalWidth;
        const scrollPosition = selectedLandmark.pixel_x * scale_x - 100;
        w.scrollTo(scrollPosition, 0);
        handleSelectElement([]);
      }, 100);
    }
  }, [selectedLandmark, canvasWidth, originalWidth]);

  // scroll to product
  useEffect(() => {
    if (
      canvasWidth &&
      originalWidth &&
      selectedProductState &&
      Object.keys(selectedProductState).length > 0 &&
      landmarkInfo?.coords?.length > 0
    ) {
      setInputZoom(90);
      setTimeout(() => {
        for (let index = 0; index < landmarkInfo.coords.length; index++) {
          const coord = landmarkInfo.coords[index];
          if (coord?.item === selectedProductState?.item) {
            const w = document.getElementById('container');
            const scale_x = w.scrollWidth / originalWidth;
            const posX = coord?.pixel_z ? coord.pixel_x : coord.facing_bottom_right_x;
            const posY = coord?.pixel_z ? coord.pixel_z : coord.facing_bottom_right_y;
            const scrollValueX = posX * scale_x;
            const scrollValueY = posY * scale_x;

            // Center the view on the selected product, accounting for container width
            const scrollOffsetX = w.offsetWidth * 0.7;
            const scrollOffsetY = w.offsetHeight * 0.7;

            w.scrollTo(scrollValueX - scrollOffsetX, scrollValueY - scrollOffsetY);
            handleProductPosPercent(posX, originalWidth);
            break;
          }
        }
      }, 1000);

    }
  }, [selectedProductState, landmarkInfo, canvasWidth, originalWidth]);

  useEffect(() => {
    clearCoordsState();
    clearWallImageData();
  }, [selectedStore]);

  // Get the type of alert of the selected product
  useEffect(() => {
    if (selectedProduct && Object.keys(selectedProduct).length > 0) {
      let productType = 'stock';
      if (selectedProduct.stockout) productType = 'stockout';
      else if (selectedProduct.spread_to_fill) productType = 'spread_to_fill';
      else if (selectedProduct.price_difference)
        productType = 'price_difference';
      else if (selectedProduct.extra_label) productType = 'extra_label';
      else if (selectedProduct.misplaced_product)
        productType = 'misplaced_product';
      else if (
        !selectedProduct.landmark_type &&
        selectedProduct.hasOwnProperty('pixel_z')
      )
        productType = 'promotion';
      else if (selectedProduct.landmark_type) productType = 'hua';
      setSelectedProductType(productType);
    }
  }, [selectedProduct]);

  // general pill selection
  const handleSelectionChanged = (value, selectedValues, setSelected) => {
    const newSet = new Set(selectedValues);
    if (newSet.has(value)) newSet.delete(value);
    else newSet.add(value);
    setSelected(newSet);
  };

  // Get the formatting (currency, numbers, data) based on the client_id
  useEffect(() => {
    if (!isEmptyOrUndefined(selectedClient, 'object')) {
      getNumberFormat(selectedClient?.client_id)
    }
  }, [selectedClient])


  useEffect(() => {
    let auxList = []
    let currentBayNames = [];
    scannedBaysData.forEach(bay => {
      const bayName = bay.client_names ? bay.client_names[0] : bay.standard_name;
      let bayInfo = countLabelTotalAndAlerts(bayName);

      // If the bay name is already in the list, we need to update the display_name to the standard_name
      if (currentBayNames.includes(bayName)) {
        const element = auxList.find(element => element.display_name === bayName);
        element.display_name = element.standard_name;
        auxList.push({ ...bay, bayInfo, pixel_x: bay.wall_bbox[0][0], display_name: bay.standard_name });
        currentBayNames = currentBayNames.filter(name => name !== bayName);
        currentBayNames.push(bay.standard_name);
      } else {
        // If the bay name is not in the list, we need to add it
        auxList.push({ ...bay, bayInfo, pixel_x: bay.wall_bbox[0][0], display_name: bayName });
        currentBayNames.push(bayName);
      }

    });
    // Sort the list by pixel_x
    auxList.sort((a, b) => {
      if (a.pixel_x < b.pixel_x)
        return -1;
      return 1;
    })
    setBaysDataWithCounts(auxList)
  }, [scannedBaysData, landmarkInfo])


  const countLabelTotalAndAlerts = (value) => {
    let info = {
      alertCount: 0,
      total: 0
    }
    if (landmarkInfo && landmarkInfo.coords && Object.keys(landmarkInfo.coords)) {
      landmarkInfo.coords.forEach(label => {
        if (value.includes(label.landmark)) {
          if (label.stockout) {
            info.alertCount++;
          }
          info.total++;
        }
      })
    }
    return info;
  }

  useEffect(() => {
    if (selectedStatus) {
      const filteredSelectedElements = selectedElements.filter(element => ((selectedStatus || {}).has(getCoordAlertName(selectedLandmark, element))));
      handleSelectElement(filteredSelectedElements);
    }
  }, [selectedStatus]);

  // Add effect to reset spatial hash when data changes
  useEffect(() => {
    // Don't create spatial hash until we have valid dimensions
    if (!canvasWidth || !originalWidth || !canvasHeight || !originalHeight) {
      return;
    }

    spatialHashRef.current = [];

    if (landmarkInfo?.coords?.length) {
      const coordsData = [
        ...(landmarkInfo.coords ?? []),
        ...(aislePromotions ?? []),
        ...(aisleLandmarks ?? []),
      ];

      const scale_x = canvasWidth / originalWidth;
      const scale_y = canvasHeight / originalHeight;

      // Only create spatial hash if we have valid scale factors
      if (scale_x > 0 && scale_y > 0) {
        spatialHashRef.current = createSpatialHash(coordsData, canvasWidth, canvasHeight, scale_x, scale_y);
      }
    }
  }, [landmarkInfo, aislePromotions, aisleLandmarks, canvasWidth, canvasHeight, originalWidth, originalHeight]);

  const getMousePos = ({ clientX: x, clientY: y, isClicked = false }) => {
    // Don't process mouse events until we have valid spatial hash
    if (!spatialHashRef.current) {
      return;
    }

    let notFound = true;

    const coordsData = [
      ...(landmarkInfo.coords ?? []),
      ...(aislePromotions ?? []),
      ...(aisleLandmarks ?? []),
    ];

    if (coordsData && coordsData.length) {
      // Get relevant grid cell for mouse position
      const col = Math.floor(x / GRID_CELL_SIZE);
      const row = Math.floor(y / GRID_CELL_SIZE);
      const cell = `${col},${row}`;

      // Check only items in current cell
      const itemsToCheck = spatialHashRef.current[cell] || [];
      const filteredItemsToCheck = itemsToCheck.filter(element => ((selectedStatus || {}).has(getCoordAlertName(selectedLandmark, element.coord))));

      let beforeClicked = null;
      for (const { idx, coord, bounds } of filteredItemsToCheck) {
        const mouseIn = x >= bounds.x1 && x <= bounds.x2 &&
          y >= bounds.y1 && y <= bounds.y2;

        coord['isHoverOn'] = mouseIn;
        if (coord['isClicked']) {
          beforeClicked = coord;
          coord['isClicked'] = false;
        }

        if (mouseIn && (!isHoverOn || idx !== selectedProductIndex.current)) {
          notFound = false;
          setIsHoverOn(true);
          if (isClicked) {
            handleSelectElement([coord]);
            selectedProductIndex.current = idx;
            setSelectedProduct(coord);

            const posX = coord?.pixel_z ? coord.pixel_x : coord.facing_bottom_right_x;
            handleProductPosPercent(posX, canvasWidth);
          }
        } else if ((mouseIn && isHoverOn) && (!isClicked || !beforeClicked)) {
          notFound = false;
        }
      }
    }

    if (notFound) {
      setIsHoverOn(false);
    }
  };

  const handleCanvasHover = event => {
    getMousePos({ clientX: event.clientX, clientY: event.clientY });
  };

  const handleCanvasLeave = event => {
    setIsHoverOn(false);
  }

  const handleProductPosPercent = (posX, canvasWidth) => {
    const posPercent = posX / canvasWidth;
    setProductPosPercent(posPercent);
    if (isWallExtend && isHoverOn) setIsWallExtend(false);
  }

  const handleCanvasClick = (event, drw) => {
    getMousePos({ clientX: event.clientX, clientY: event.clientY, isClicked: true });
    handleMetadataOpenChange(false);
  };

  const handleExcelDownload = all_data => {
    if (all_data) {
      getCoordsForExcel(
        selectedStore.store_id,
        selectedClient.supplier_id,
        selectedAisle['session'],
        format(dateState, null, 'yyyy-MM-dd'),
      );
    } else {
      const columns = [
        'item',
        'ean',
        'price',
        'aisle',
        'landmark',
        'texts',
        'name_category',
        ...excelColumns,
      ];
      const columnsTranslated = [t('cws_app.Walls.date', 'Date'), ...columns].map(
        col => getChainMeaning(t, selectedStore?.chain_name, col),
      );
      const formattedDate = format(dateState, i18n.language, clientFormats.date_format);
      const data = landmarkInfo?.coords?.map(position => {
        return [
          formattedDate,
          ...columns.map(col => position[col]),
        ];
      });

      const filename = `Shelf ${selectedStore?.store_code}-(${selectedAisle?.code})-${formattedDate.replaceAll('/', '-')}`;
      getDigitalShelfExcel({
        excelData: [columnsTranslated, ...data],
        excelFileName: filename,
      });
    }
    setModalOpen(false);
  };

  useEffect(() => {
    if (coordsForExcel) {
      const columns = [
        'item',
        'ean',
        'price',
        'aisle',
        'landmark',
        'texts',
        'name_category',
        ...excelColumns,
      ];
      let columnsTranslated = [t('cws_app.Walls.date', 'Date'), ...columns].map(
        col => getChainMeaning(t, selectedStore?.chain_name, col),
      );
      const formattedDate = format(dateState, i18n.language, clientFormats.date_format);
      const data = coordsForExcel?.coords?.map(position => {
        return [
          formattedDate,
          ...columns.map(col => position[col]),
        ];
      });

      const filename = `Shelf ${selectedStore?.store_code}-${formattedDate.replaceAll('/', '-')}`;
      getDigitalShelfExcel({
        excelData: [columnsTranslated, ...data],
        excelFileName: filename,
      });
    }
  }, [coordsForExcel]);

  const checkLandmarksAvailable = () => {
    return landmarkInfo.coords && landmarkInfo.coords.length > 0;
  };

  // on window resize
  const handleResize = (isWallExtend) => {
    if (wallBoxRef.current) {
      const SIDE_METADATA_SIZE = 60;
      const fullWidth = wallBoxRef.current?.offsetWidth;
      const REDUCE_FACTOR = 0.93;
      const newWidth = !isWallExtend ? (fullWidth ? fullWidth * REDUCE_FACTOR : '20') : (fullWidth - SIDE_METADATA_SIZE);

      if (fullWidth - newWidth < SIDE_METADATA_SIZE) {
        wallBoxRef.current.style.flexDirection = 'column';
        if (wallScroll.current?.style?.maxWidth !== undefined) wallScroll.current.style.maxWidth = `${fullWidth}px`;
        wallViewRef.current.style.width = `${fullWidth}px`;
      } else {
        wallBoxRef.current.style.flexDirection = 'row';
        if (wallScroll.current?.style?.maxWidth !== undefined) wallScroll.current.style.maxWidth = isWallExtend ? '100%' : `${fullWidth * 0.7}px`;
        wallViewRef.current.style.width = `${newWidth}px`;
      }
    }
  };
  useEffect(() => {
    handleResize(true);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [wallBoxRef]);

  const handleMetadataOpenChange = isOpen => {
    setIsWallExtend(isOpen);
    handleResize(isOpen);
  }

  return (
    <>
      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        title={t('cws_app.Walls.downloadModalTitle')}
        description={t('cws_app.Walls.downloadModalDescription')}>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'gray' }}
          onClick={() => handleExcelDownload(false)}>
          {t('cws_app.Walls.shelfData')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleExcelDownload(true)}>
          {t('cws_app.Walls.allData')}
        </Button>
      </Modal>
      <Box ref={wallBoxRef} sx={{ mt: 1, display: 'flex', gap: '0.5em', flexDirection: 'row', width: '100%' }}>
        <Collapse
          in={isWallExtend}
          collapsedSize={wallBoxRef.current?.offsetWidth * 0.7 ?? '50em'}
          orientation="horizontal"
          sx={{ flex: 'none' }}
        >
          <Grid container ref={wallViewRef} sx={{ height: '100%' }}>
            <Grid xs={12} ref={wallScroll} id="wallScroll" item container sx={{ maxWidth: isWallExtend ? '100%' : `${wallBoxRef.current?.offsetWidth * 0.7}px`, maxHeight: '32em' }}>
              <Grid
                item
                direction={'row'}
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 2, sm: 8, md: 12 }}
                sx={{
                  height: '100%',
                  width: '100%',
                  borderRadius: '5px',
                  backgroundColor: '#1a242d',
                  WebkitOverflowScrolling: 'touch !important',
                  minHeight: '400px',
                  overflowX: 'auto',
                  overflowY: 'hidden',
                  position: 'relative',
                  display: 'flex',
                }}>
                {!isLoadingWallImage &&
                  Object.keys(wallImageData).length > 0 && //image loaded
                  !isLoadingCoordsInfo ? (
                  // bug drawer wall canvas
                  <WallDrawer
                    aisle={selectedAisle}
                    landmark={selectedLandmark}
                    png={wallImageData.signed_url}
                    imageMetadata={wallImage}
                    coords={landmarkInfo.coords}
                    wallLandmarks={wallLandmarks}
                    canvasHeight={canvasHeight}
                    canvasWidth={canvasWidth}
                    originalWidth={originalWidth}
                    originalHeight={originalHeight}
                    selectedStatus={selectedStatus}
                    selectedCategories={selectedCategories}
                    colors={COLORS}
                    product={selectedProductState}
                    handleCanvasHover={handleCanvasHover}
                    handleCanvasLeave={handleCanvasLeave}
                    handleCanvasClick={handleCanvasClick}
                    aislePromotions={aislePromotions}
                    aisleLandmarks={aisleLandmarks}
                    selectedAisle={selectedAisle}
                    selectedStore={selectedStore}
                    imgsDict={imgsDict}
                    selectedElements={selectedElements}
                    handleCoordAlertInfo={handleCoordAlertInfo}
                    getCoordAlertName={getCoordAlertName}
                    isHoverOn={isHoverOn}
                    scannedBays={baysDataWithCounts}
                    inputZoom={inputZoom}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
        {/* Wall metadata */}
        <Box sx={{
          width: isWallExtend ? '60px' : `${Math.round(wallBoxRef.current?.offsetWidth * 0.3)}px`,
          webKitTransition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
        }}>
          <WallMetadata
            parsedLandmarks={parsedLandmarks}
            selectedAisle={selectedAisle}
            isLoadingMaps={isLoadingMaps}
            storeMaps={storeMaps}
            chainName={selectedStore?.chain_name}
            onOpenChange={handleMetadataOpenChange}
            isOpen={!isWallExtend}
            currencySymbol={currencySymbol}
            clientFormats={clientFormats}
          />
        </Box>
      </Box>

      {!isLoadingWallImage &&
        Object.keys(wallImageData).length > 0 &&
        !isLoadingCoordsInfo &&
        Object.keys(landmarkInfo).length > 0 ? (
        <Grid
          item
          xs={12}
          container
          sx={{ mt: 1 }}
          justifyContent="flex-start">
          <Grid
            item
            container
            xs={12}
            sm={'auto'}
            alignContent="center"
            justifyContent="center"
            sx={{ mt: 1, mr: 1, p: 1, borderRadius: 2 }}
            style={{ backgroundColor: '#1a242d' }}>
            {checkLandmarksAvailable() ? (
              <>
                {' '}
                {!loadingCoordsForExcel ? (
                  <Grid item xs>
                    <Tooltip title={t('cws_app.Walls.download_excel')}>
                      <IconButton
                        color="secondary"
                        size="large"
                        onClick={() => setModalOpen(true)}>
                        <InsertDriveFileIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : (
                  <Grid
                    item
                    container
                    xs
                    alignContent="center"
                    justifyContent="center"
                    m={1}>
                    <CircularProgress color="secondary" size={24} sx={{}} />
                  </Grid>
                )}
              </>
            ) : null}
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={6}
            alignContent="center"
            wrap="nowrap"
            sx={{ mt: 1, p: 1, borderRadius: 2 }}
            style={{
              backgroundColor: '#1a242d',
              width: '100%',
              overflowX: 'auto',
            }}>
            {landmarkInfo.coords.length
              ? Object.keys(alertPills).map(pillName => {
                // Bugs filter (the ones at the bottom of the page)
                if (alertPills[pillName] > 0) {
                  return (
                    <Grid item key={pillName}>
                      <Tooltip title={'Total: ' + alertPills[pillName]}>
                        <Chip
                          clickable
                          label={
                            pillName === 'hua'
                              ? t(
                                'Chains.' +
                                selectedStore?.chain_name +
                                '.landmark',
                              )
                              : pillName === 'promotion'
                                ? t('cws_app.digital_store.promotions')
                                : t('cws_app.Walls.' + pillName)
                          }
                          variant="contained"
                          size="large"
                          sx={{
                            m: 1,
                            px: 1,
                            color: COLORS[pillName].text,
                            fontSize: '14px',
                            fontWeight: '400',
                          }}
                          style={{
                            backgroundColor: selectedStatus.has(pillName)
                              ? COLORS[pillName].color
                              : COLORS[pillName].color2,
                          }}
                          onClick={() => {
                            handleSelectionChanged(
                              pillName,
                              selectedStatus,
                              setSelectedStatus,
                            );
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  );
                }
                return null;
              })
              : null}
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={true}
            wrap="nowrap"
            spacing={2}
            sx={{ mt: 1, ml: 1, pt: -1, pb: 1, borderRadius: 2 }}
            style={{
              backgroundColor: '#1a242d',
              width: '100%',
              overflowX: 'auto',
            }}>
            {categoryFilters && categoryFilters.length
              ? categoryFilters.map(cat => {
                return (
                  <Grid item key={cat}>
                    <Chip
                      clickable
                      label={cat}
                      variant="contained"
                      size="large"
                      sx={{
                        color: 'black',
                        fontSize: '14px',
                        fontWeight: '400',
                      }}
                      style={{
                        backgroundColor: !selectedCategories.has(cat)
                          ? '#CACADD'
                          : '#697075',
                      }}
                      onClick={() => {
                        handleSelectionChanged(
                          cat,
                          selectedCategories,
                          setSelectedCategories,
                        );
                      }}
                    />
                  </Grid>
                );
              })
              : null}
          </Grid>
        </Grid>
      ) : null}
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={isLoadingWallImage || isLoadingCoordsInfo}>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          direction="column">
          <Typography>
            {t('cws_app.Walls.loading_wall', 'Loading Wall Image')}
          </Typography>
          <CircularProgress color="secondary" />
        </Grid>
      </Backdrop>
    </>
  );
}
