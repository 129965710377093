import qs from 'querystring';

export default function HTTPService() {
  const HTTP_METHOD_GET = 'GET';
  const HTTP_METHOD_POST = 'POST';
  const HTTP_METHOD_PUT = 'PUT';
  const HTTP_METHOD_PATCH = 'PATCH';
  const HTTP_METHOD_DELETE = 'DELETE';
  const HTTP_HEADER_ACCEPT = 'Accept';
  const HTTP_HEADER_CONTENT_TYPE = 'Content-Type';
  const HTTP_CREDENTIALS = 'credentials';
  const HTTP_CREDENTIALS_TYPE = 'include';

  const MIME_TYPE_JSON = 'application/json;charset=utf-8';
  const { REACT_APP_LOGING_MODE } = process.env;

  return {
    getAuthenticated,
    postAuthenticated,
    putAuthenticated,
    patchAuthenticated,
    deleteAuthenticated,
  };

  function getToken() {
    const token = localStorage.getItem('rekol');
    return token ? token : '';
  }

  function getAuthenticated({ url, query, signal }) {
    return makeRequest({
      method: HTTP_METHOD_GET,
      url,
      query,
      headers: { 'token': getToken() },
      signal,
    });
  }

  function postAuthenticated({ url, body, content, signal }) {
    return makeRequest({
      method: HTTP_METHOD_POST,
      url,
      body,
      content,
      headers: { 'token': getToken() },
      signal,
    });
  }

  function putAuthenticated({ url, body, content, signal }) {
    return makeRequest({
      method: HTTP_METHOD_PUT,
      url,
      body,
      content,
      headers: { 'token': getToken() },
      signal,
    });
  }

  function patchAuthenticated({ url, body, content, signal }) {
    return makeRequest({
      method: HTTP_METHOD_PATCH,
      url,
      body,
      content,
      headers: { 'token': getToken() },
      signal,
    });
  }

  function deleteAuthenticated({ url, body, content, signal }) {
    return makeRequest({
      method: HTTP_METHOD_DELETE,
      url,
      body,
      content,
      headers: { 'token': getToken() },
      signal,
    });
  }

  async function makeRequest({
    method,
    url,
    content,
    query = {},
    body = {},
    headers = {},
    signal,
  }) {
    const finalUrl =
      query && Object.keys(query).length
        ? `${url}?${qs.stringify(query)}`
        : url;

    const finalHeaders = Object.keys(headers).reduce((acc, headerKey) => {
      acc.append(headerKey, headers[headerKey]);
      return acc;
    }, new Headers());

    finalHeaders.set(HTTP_HEADER_ACCEPT, MIME_TYPE_JSON);

    if (content !== 'raw' && (method === HTTP_METHOD_POST || method === HTTP_METHOD_PUT || method === HTTP_METHOD_PATCH || method === HTTP_METHOD_DELETE)) {
      finalHeaders.set(HTTP_HEADER_CONTENT_TYPE, MIME_TYPE_JSON);
    }

    const requestDescription = {
      method,
      headers: finalHeaders,
      [HTTP_CREDENTIALS]: HTTP_CREDENTIALS_TYPE,
      signal,
      ...(method === HTTP_METHOD_POST || method === HTTP_METHOD_PUT || method === HTTP_METHOD_PATCH || method === HTTP_METHOD_DELETE
        ? { body: content !== 'raw' ? JSON.stringify(body) : body }
        : {}),
    };

    let response;
    try {
      response = await fetch(finalUrl, requestDescription);
    } catch (error) {
      if (error.name === 'AbortError') {
        throw error;
      }
      console.log(`%c error fetching with error: ${error} in ${finalUrl}`, 'color: #BB0042');
      response = error;
    }

    let responseBody = {};
    try {
      responseBody = await response.json();
    } catch (error) {
      if (REACT_APP_LOGING_MODE == 'DEV') {
        console.log(`%c error parsing response body ${error} in ${finalUrl}`, 'color: #FF0042');
      }
    }

    if (response.ok) {
      return responseBody;
    } else {
      if (response.status === 302) {
        localStorage.removeItem('blobDate');
        window.location.reload();
      }
      else if (responseBody && Object.keys(responseBody).length > 0) {
        // error with message to display in snack bar
        throw new Error(responseBody.message);
      } else {
        try {
          // handle everything else
          const responseText = await response.text();
          throw new Error(responseText);
        } catch (error) {
          // connection errors
          throw new Error('Internal Server Error');
        }
      }
    }
  }
}
