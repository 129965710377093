import { Box, Grid, LinearProgress, Paper, Typography, useTheme, Button } from "@mui/material";
import { alphabeticSortByParam, naturalSort } from 'core/utils/parsers';
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';

import { isEmptyOrUndefined } from "@zippeditoolsjs/blocks";

import CircularLoader from "../../tools/CircularLoader";
import usePrevious from '../../tools/prevHook';
import { colorBasedOnTheme } from "../../utils/colors";
import DownloadSection from "./DownloadSection";
import InteractiveMap from "./InteractiveMap";
import NavigationMap from "./NavigationMap";
import MapSelectors from "./MapSelectors";
import Sidenav from "./Sidenav";
import TaskProgress from "./TaskProgress";
import TaskTable from './TaskTable';
import { getConnectionTimeState } from "core/selectors";

const CANVASWIDTH = 1000;
export default function DigitalStore(props) {
  const {
    setSnackAlert,
    isExcelLoading,
    isInvLoading,
    isPdfLoading,
    getDigitalStoreExcel,
    getDigitalStoreInv,
    getDigitalStorePdf,
    isLoadingMapInfo,
    selectedClient,
    getDSInfo,
    storeMap,
    getUserStores,
    isLoadingUserStores,
    userStores,
    getTaskTypes,
    isLoadingTaskTypes,
    taskTypes,
    getStoreCategories,
    isLoadingCategories,
    storeCategories,
    linkReceived,
    getAisleThumbnail,
    isLoadingAisleThumbnail,
    aisleThumbnail,
    getExcelByDate,
    isLoadingExcelByDate,
    getUserSuppliers,
    suppliers,
    isLoadingSuppliers,
    getResumeDStore,
    resumeDStore,
    clientFormats,
    getStoreAvailableSessions,
    storeAvailableSessions,
    isStoreAvailableSessionsLoading,
    getDSConnectionTime,

    connectionTime,

  } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const sidenavRef = useRef(null); // To scroll to the sidenav
  const heightRef = useRef(null) // To set the height of the sidenav
  // Map states
  const [robotSession, setRobotSession] = useState(null);
  const prevRobotSession = usePrevious(robotSession);
  const [refresh, setRefresh] = useState(false); // Get the tasks again and reload map
  // Map selector states
  const [date, setDate] = useState(new Date());
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedStoreTaskType, setSelectedStoreTaskType] = useState(null);
  // Sidenav states
  const [isSidenavOpen, setIsSidenavOpen] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [aislesList, setAislesList] = useState([]) // All aisles, grouped by section (tramo) with it's metadata and list of alerts
  const [aisleLandmarkMap, setAisleLandmarkMap] = useState({}) // All aisles, each with a list of indexes that are used to access it's section (tramo) in the aisle
  const [height, setHeight] = useState(0);
  // Table states
  const [groupColumnInfo, setGroupColumnInfo] = useState({});
  const [aislesByTask, setAislesByTask] = useState([]);
  const [subCategoriesByTask, setSubCategoriesByTask] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [isTaskLoading, setIsTaskLoading] = useState(false);
  const [filteredTaskData, setFilteredTaskData] = useState([]);
  const [supplier, setSupplierState] = useState(null);
  const [resumeBiData, setResumeBiData] = useState(null);

  // Methods
  const handleGetMap = (robotSession) => {
    const parsedDate = format(date, 'yyyy-MM-dd');
    const storeId = selectedStore.store_id;
    const taskType = selectedStoreTaskType.task_name;
    const [category_i, level] = [selectedCategory?.value, selectedCategory?.level];
    const [include_map, session, mapInfoRequest] = [selectedStoreTaskType?.has_map, null, null]; //TODO: use this params when we using cache

    getDSInfo({
      client_id: selectedClient.client_id,
      store_id: storeId,
      task_type: taskType,
      category_i,
      sub_category_level: typeof level === 'number' ? level + 1 : null,
      supplier_id: supplier?.id,
      include_map,
      session,
      mapInfoRequest,
      date: parsedDate,
      fixed_width: CANVASWIDTH,
      robot_session: robotSession
    })
    setRefresh(false);
  }

  // Lifecycle methods
  useEffect(() => {
    if (!isLoadingMapInfo
      && (refresh || (
        date
        && !isEmptyOrUndefined(selectedStore, 'object')
        && !isEmptyOrUndefined(selectedStoreTaskType, 'object')
        && selectedClient?.is_active
      ))) {
      handleGetMap(null);
    }
  }, [date, selectedStore, selectedStoreTaskType, refresh, supplier]);

  useEffect(() => {
    if (!isLoadingMapInfo && robotSession && prevRobotSession) {
      handleGetMap(robotSession);
    }
  }, [robotSession])

  useEffect(() => {
    if (supplier?.id && selectedStore?.store_id && selectedClient?.client_id) {
      getStoreCategories(selectedStore.store_id, selectedClient.client_id, false, supplier?.id || selectedClient?.supplier_id)
    }
  }, [supplier]);

  // TODO: handle messages
  useEffect(() => {
    if (storeMap?.message) {
      let snack = {
        open: true,
        message: '',
        severity: 'warning',
      }
      let resetParamByError = false;
      if (storeMap.message === 'without_report') {
        snack['message'] = t('cws_app.digital_store.not_found_report_404', 'The selected report is not available for this date');
        setSnackAlert(snack);
        resetParamByError = true;
      } else if (storeMap.message === 'without_tasks') {
        snack['message'] = t('cws_app.digital_store.not_found_task', 'The selected task does not have alerts for this date');
        setSnackAlert(snack);
        resetParamByError = true;
      } else if (storeMap.status !== 200) {
        snack['message'] = t('cws_app.digital_store.not_found_report_500', 'The selected report is not available');
        setSnackAlert(snack);
        resetParamByError = true;
      }

      if (resetParamByError) {
        setResumeBiData(null);
      }
    }
    if (storeMap?.task_table_info) {
      handleFilterTasksByCategory();
    }
    // If there's no robot selected, set robot with the first session
    if (selectedStore && selectedStoreTaskType && !robotSession && storeMap?.chain_country_id === selectedStore?.chain_country_id) {
      setRobotSession(storeMap?.sessions?.[0]?.session)
      if (robotSession) {
        handleGetMap(storeMap?.sessions?.[0]?.session)
      }
    }
  }, [storeMap])

  // Scroll to the sidenav when it's opened
  useEffect(() => {
    if (isSidenavOpen) {
      sidenavRef.current.scrollIntoView()
    }
  }, [isSidenavOpen])

  // Set the height of the sidenav based on the height of the map
  useEffect(() => {
    setHeight(heightRef?.current?.clientHeight)
  }, [heightRef?.current?.clientHeight])

  useEffect(() => {
    if (selectedClient?.client_id && selectedStore?.chain_name) {
      getUserSuppliers(selectedClient.client_id, [selectedStore.chain_name], false, selectedStore?.store_code, selectedCategory?.category_name);
    }
    if (selectedStoreTaskType?.task_id && !([3, 15].includes(selectedStoreTaskType.task_id))) {
      handleFilterTasksByCategory();
    } else if (selectedStoreTaskType?.task_id) {
      handleGetMap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory])

  useEffect(() => {
    if (Object.keys(resumeDStore).length > 0) {
      setResumeBiData(resumeDStore);
    } else {
      setResumeBiData(null);
    }
  }, [resumeDStore])


  // Methods
  const setFilters = (taskData, subCategoriesByTask) => {
    let groupColumnInfoAux = {};
    if (storeMap?.task_table_info && (taskData[0]?.aisle || taskData[taskData.length - 1]?.aisle)) {
      const aislesClientNames = storeMap?.map_info?.client_linear_names ? storeMap?.map_info?.client_linear_names : {};
      taskData.forEach(row => {
        const part = row.aisle_part ? `-${row.aisle_part}` : '';
        const orient = row.orient ? `-${row.orient}` : '';
        const aisleName = `${row.aisle}${orient}${part}`;
        const thereIsAisle = groupColumnInfoAux[aisleName];

        // Change to client name
        const clientAisleName = aislesClientNames.hasOwnProperty(aisleName) ? aislesClientNames[aisleName] : aisleName;
        if (!thereIsAisle) groupColumnInfoAux[aisleName] = { name: aisleName, client_aisle_name: String(clientAisleName) };
      });
      const aislesByTask = Object.keys(groupColumnInfoAux).map(row => { return { ...groupColumnInfoAux[row] } })
      setAislesByTask(naturalSort(aislesByTask, 'name'));
    }
    if (subCategoriesByTask?.length > 0) {
      alphabeticSortByParam(subCategoriesByTask);
      groupColumnInfoAux = {};
      subCategoriesByTask.forEach(row => {
        groupColumnInfoAux[row.value] = { name: row.name };
      });
      setSubCategoriesByTask(subCategoriesByTask)
    } else {
      setSubCategoriesByTask([])
    }
    setGroupColumnInfo(groupColumnInfoAux);
  }

  const handleFilterTasksByCategory = () => {
    setIsTaskLoading(true);
    const categoryName = selectedCategory?.value;

    if (!categoryName || categoryName === 'All') {
      setTaskData(storeMap?.task_table_info);
      setResumeBiData(storeMap?.resume_bi_data);
      setFilters(storeMap?.task_table_info, storeMap.sub_categories_list);
    } else {
      const newTaskData = storeMap?.task_table_info?.filter(row => row.main_category === selectedCategory.category_name);

      setTaskData(newTaskData);
      setFilters(newTaskData, storeMap.sub_categories_list);
      setRobotSession(storeMap?.sessions?.[0]?.session);

      if (newTaskData?.length) {
        getResumeDStore({ taskData: newTaskData, taskType: selectedStoreTaskType.task_name, taskGrouping: storeMap.group_by_parameter })
      } else {
        setResumeBiData([{ "key": "total_labels", "value": 0, "info": "total_labels_info" }]);
      }
    }

    setTimeout(() => {
      setIsTaskLoading(false);
    }, 500);
  }

  return (
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} pt={4} px={2}>
      {/* Page title */}
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant='h4' sx={{ color: colorBasedOnTheme(theme, 'color') }}>{t('cws_app.digital_store.digital_store', 'Digital Store')}</Typography>
        {(isInvLoading || isPdfLoading || isExcelLoading) &&
          <LinearProgress sx={{ width: '100%', mx: 'auto', borderRadius: '15em' }} color="secondary" />}
      </Grid>
      {/* Map selectors */}
      <Grid item xs={12} sm={12} md={12}>
        <MapSelectors
          date={date}
          setDate={setDate}
          selectedStore={selectedStore}
          setSelectedStore={setSelectedStore}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          selectedStoreTaskType={selectedStoreTaskType}
          setSelectedStoreTaskType={setSelectedStoreTaskType}
          getUserStores={getUserStores}
          isLoadingUserStores={isLoadingUserStores}
          userStores={userStores}
          selectedClient={selectedClient}
          getTaskTypes={getTaskTypes}
          isLoadingTaskTypes={isLoadingTaskTypes}
          taskTypes={taskTypes}
          getStoreCategories={getStoreCategories}
          isLoadingCategories={isLoadingCategories}
          storeCategories={storeCategories}
          t={t}
          linkReceived={linkReceived}
          setIsSidenavOpen={setIsSidenavOpen}
          isLoadingMapInfo={isLoadingMapInfo}
          setRobotSession={setRobotSession}
          setSelectedAlert={setSelectedAlert}
          setGroupColumnInfo={setGroupColumnInfo}
          supplier={supplier}
          setSupplierState={setSupplierState}
          getUserSuppliers={getUserSuppliers}
          isLoadingSuppliers={isLoadingSuppliers}
          suppliers={suppliers}
          clientFormats={clientFormats}
          getStoreAvailableSessions={getStoreAvailableSessions}
          storeAvailableSessions={storeAvailableSessions}
          isStoreAvailableSessionsLoading={isStoreAvailableSessionsLoading}
          setSnackAlert={setSnackAlert}
        />
      </Grid>
      {
        (selectedStoreTaskType?.task_name === 'robot_navigation') ?
          <Grid item xs={12} mt={{ xs: 2, md: 0 }} ml={0} display='flex' justifyContent={{ xs: null, sm: 'center' }} alignItems='center'>
            <DownloadSection
              displayedColumns={[]}
              storeCategories={storeCategories}
              chain={selectedStore?.chain_name}
              filteredTaskData={filteredTaskData}
              isDataFiltered={false}

              client_id={selectedClient?.client_id}
              signed_logo={selectedClient?.signed_logo}
              supplier_id={selectedClient?.supplier_id ? selectedClient?.supplier_id : supplier?.id}

              inputDate={format(date, 'yyyy-MM-dd')}
              inputTaskType={selectedStoreTaskType?.task_name}
              inputStoreId={selectedStore?.store_id}
              inputStoreName={selectedStore?.parsed_name}

              getDigitalStoreExcel={getDigitalStoreExcel}
              getDigitalStorePdf={getDigitalStorePdf}
              getDigitalStoreInv={getDigitalStoreInv}
              getExcelByDate={getExcelByDate}

              isExcelLoading={isExcelLoading}
              isPdfLoading={isPdfLoading}
              isInvLoading={isInvLoading}
              isLoadingExcelByDate={isLoadingExcelByDate}

              linkReceived={linkReceived}

              selectedCategory={selectedCategory}

              clientFormats={clientFormats}
            />
          </Grid>
          : null
      }
      {!selectedStoreTaskType ?
        // Default text when there's no task type selected
        <Grid item xs={12} sm={12} md={12}>
          <Paper sx={{ p: 2 }}>
            <Typography textAlign={'center'} > {t('cws_app.general.select_information_to_display', 'Select Information to Display')}</Typography>
          </Paper>
        </Grid>
        :
        // TODO: remove when the navigation feature is done
        (selectedStoreTaskType?.task_name === 'robot_navigation_2_test') ?
          <>
          <Grid item xs={12} sm={12} md={12}>
              <Grid container justifyContent='center' sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}>
                {(isTaskLoading || isLoadingMapInfo) && !isEmptyOrUndefined(selectedStoreTaskType, 'object') ?
                  <CircularLoader />
                  :
                  (storeMap?.map_info && !isEmptyOrUndefined(selectedStoreTaskType, 'object')) ?
                    <NavigationMap
                      chain={selectedStore?.chain_name}
                      storeMap={storeMap}
                      taskData={taskData}
                      hasRobot={storeMap.has_robot}
                      // TODO: FIX THE NEW CATEGORYOPTIONS -> storeCategories
                      storeCategories={storeCategories}
                      taskType={selectedStoreTaskType.task_name}
                      selectedAlert={selectedAlert}
                      setSelectedAlert={setSelectedAlert}
                      setAisleLandmarkMap={setAisleLandmarkMap}
                      setAislesList={setAislesList}
                      robotSession={robotSession}
                      setRobotSession={setRobotSession}
                      setRefresh={setRefresh}
                      setHeight={setHeight}
                      heightRef={heightRef}
                    />
                    :
                    <Box>
                      <Typography textAlign={'center'} >No hay mapa</Typography>
                    </Box>
                }
              </Grid>
          </Grid>
          </>
          :
          <>
            {!(isTaskLoading || isLoadingMapInfo) && !isEmptyOrUndefined(selectedStoreTaskType, 'object') && resumeBiData &&
              // Task progress cards
              <Grid item xs={12} sm={12} md={12}>
                <TaskProgress chain={selectedStore?.chain_name} data={resumeBiData} />
              </Grid>
            }
            {/* Map and sidenav */}
            <Grid item xs={12} sm={12} md={12}>
              <Grid container justifyContent='center' sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}>
                {(isTaskLoading || isLoadingMapInfo) && !isEmptyOrUndefined(selectedStoreTaskType, 'object') ?
                  <CircularLoader />
                  :
                  (storeMap?.map_info && !isEmptyOrUndefined(selectedStoreTaskType, 'object')) ?
                    <InteractiveMap
                      chain={selectedStore?.chain_name}
                      storeMap={storeMap}
                      taskData={taskData}
                      hasRobot={storeMap.has_robot}
                      // TODO: FIX THE NEW CATEGORYOPTIONS -> storeCategories
                      storeCategories={storeCategories}
                      taskType={selectedStoreTaskType.task_name}
                      setIsSidenavOpen={setIsSidenavOpen}
                      selectedAlert={selectedAlert}
                      setSelectedAlert={setSelectedAlert}
                      setAisleLandmarkMap={setAisleLandmarkMap}
                      setAislesList={setAislesList}
                      robotSession={robotSession}
                      setRobotSession={setRobotSession}
                      setRefresh={setRefresh}
                      setHeight={setHeight}
                      heightRef={heightRef}
                    />
                    :
                    <Box></Box>
                }
                {(!(isTaskLoading || isLoadingMapInfo) && isSidenavOpen) &&
                  <Sidenav
                    t={t}
                    setIsSidenavOpen={setIsSidenavOpen}
                    selectedAlert={selectedAlert}
                    setSelectedAlert={setSelectedAlert}
                    getAisleThumbnail={getAisleThumbnail}
                    isLoadingAisleThumbnail={isLoadingAisleThumbnail}
                    aisleThumbnail={aisleThumbnail}
                    selectedClient={selectedClient}
                    selectedStore={selectedStore}
                    date={date}
                    aisleLandmarkMap={aisleLandmarkMap}
                    aislesList={aislesList}
                    height={height}
                    sidenavRef={sidenavRef}
                    robotSession={robotSession}
                  />
                }
              </Grid>
            </Grid>

            {/* Table */}
            <Grid item xs={12} sm={12} md={12} overflow='auto'>
              {
                (!(isTaskLoading || isLoadingMapInfo) && (storeMap?.map_info || storeMap?.task_table_info) && selectedStoreTaskType && storeMap?.columns) ?
                  <TaskTable
                    aislesByTask={aislesByTask}
                    subCategoriesByTask={subCategoriesByTask}

                    inputTaskData={taskData}
                    filteredTaskData={filteredTaskData}
                    setFilteredTaskData={setFilteredTaskData}

                    displayedColumns={storeMap?.columns}
                    chain={selectedStore?.chain_name}
                    groupColumnInfo={groupColumnInfo}
                    groupParameter={storeMap?.group_by_parameter}
                    aislesClientNames={storeMap?.map_info?.client_linear_names}

                    storeMap={storeMap}
                    selectedClient={selectedClient}
                    date={date}
                    selectedStore={selectedStore}
                    selectedStoreTaskType={selectedStoreTaskType}
                    selectedCategory={selectedCategory}
                    storeCategories={storeCategories}

                    getDigitalStoreExcel={getDigitalStoreExcel}
                    getDigitalStorePdf={getDigitalStorePdf}
                    getDigitalStoreInv={getDigitalStoreInv}
                    isExcelLoading={isExcelLoading}
                    isPdfLoading={isPdfLoading}
                    isInvLoading={isInvLoading}
                    getExcelByDate={getExcelByDate}
                    isLoadingExcelByDate={isLoadingExcelByDate}

                    linkReceived={linkReceived}

                    robotSession={robotSession}

                    supplier_id={selectedClient?.supplier_id ? selectedClient?.supplier_id : supplier?.id}
                    resumeBiData={resumeBiData}
                    clientFormats={clientFormats}
                  />
                  :
                  <Box></Box>
              }
            </Grid>
          </>
      }
    </Grid>
  );
}
