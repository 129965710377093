import { createContext, useState } from 'react';

export const DigitalShelfContext = createContext();

export function DigitalShelfProvider({ children }) {
    const [selectedStore, setSelectedStore] = useState(null);
    const [dateState, setDateState] = useState(new Date());
    const [selectedProduct, setSelectedProduct] = useState({});
    const [misplacedSignedImages, setMisplacedSignedImages] = useState([]);
    const [wallSignedUrl, setWallSignedUrl] = useState(null);
    const [zoomedWallImageStyle, setZoomedWallImageStyle] = useState({
        height: 140,
        width: 310,
        position: 'relative',
    });
    const [missingLabelInfo, setMissingLabelInfo] = useState(null);
    const [selectedAisle, setAisleState] = useState(null);
    const [productPosPercent, setProductPosPercent] = useState(null);
    const [selectedLandmark, setLandmarkState] = useState(null);
    const [productSearched, setProductSearched] = useState(null);
    const [selectedFloor, setSelectedFloor] = useState(null);
    const [baysDataWithCounts, setBaysDataWithCounts] = useState([])

    return (
        <DigitalShelfContext.Provider
            value={{
                selectedStore,
                setSelectedStore,
                dateState,
                setDateState,
                selectedProduct,
                setSelectedProduct,
                misplacedSignedImages,
                setMisplacedSignedImages,
                wallSignedUrl,
                setWallSignedUrl,
                missingLabelInfo,
                setMissingLabelInfo,
                zoomedWallImageStyle,
                setZoomedWallImageStyle,
                selectedAisle,
                setAisleState,
                productPosPercent,
                setProductPosPercent,
                selectedLandmark,
                setLandmarkState,
                productSearched,
                setProductSearched,
                selectedFloor,
                setSelectedFloor,
                baysDataWithCounts,
                setBaysDataWithCounts,
            }}
        >
            {children}
        </DigitalShelfContext.Provider>
    );
}
