import { connect } from 'react-redux';
import DigitalStore from '../../../../components/features/digitalStore/DigitalStore';

import { getAisleThumbnail, getDSInfo, getDigitalStoreExcel, getDigitalStoreInv, getDSConnectionTime, getDigitalStorePdf, getExcelByDate, getResumeDStore, getStoreMaps } from 'core/slices/digitalStore';

import {
    aisleThumbnail,
    getCategoriesList,
    getClientFormats,
    getConnectionTimeState,
    getLinkState,
    getResumeDStoreData,
    getSelectedClient,
    getStoreAvailableSessionsState,
    getStoreMapInfo,
    getUserStoresAllList,
    isExcelLoading,
    isInvLoading,
    isLoadingAisleThumbnail,
    isLoadingCategories,
    isLoadingClientFormats,
    isLoadingExcelByDate,
    isLoadingMapInfo,
    isLoadingResumeDStore,
    isLoadingConnectionTime,
    isLoadingTaskTypes,
    isLoadingUserStores,
    isLoadingUserSuppliers,
    isPdfLoading,
    isStoreAvailableSessionsLoading,
    taskTypes,
    userSuppliers,

    getStoreMapsObject,
    isLoadingMaps,
    
} from 'core/selectors';
import { setSnackAlert } from 'core/slices/snackAlert';
import {
    getStoreAvailableSessions,
    getStoreCategories,
} from 'core/slices/store';
import { getTaskTypes } from 'core/slices/tasks';
import { getUserStores, getUserSuppliers } from 'core/slices/users';

export default connect(state => ({
    isExcelLoading: isExcelLoading(state),
    isInvLoading: isInvLoading(state),
    isPdfLoading: isPdfLoading(state),
    selectedClient: getSelectedClient(state),
    isLoadingMapInfo: isLoadingMapInfo(state),
    storeMap: getStoreMapInfo(state),
    isLoadingUserStores: isLoadingUserStores(state),
    userStores: getUserStoresAllList(state),
    isLoadingTaskTypes: isLoadingTaskTypes(state),
    taskTypes: taskTypes(state),
    isLoadingCategories: isLoadingCategories(state),
    storeCategories: getCategoriesList(state),
    linkReceived: getLinkState(state),
    isLoadingAisleThumbnail: isLoadingAisleThumbnail(state),
    aisleThumbnail: aisleThumbnail(state),
    isLoadingExcelByDate: isLoadingExcelByDate(state),
    suppliers: userSuppliers(state),
    isLoadingSuppliers: isLoadingUserSuppliers(state),
    isLoadingResumeDStore: isLoadingResumeDStore(state),
    resumeDStore: getResumeDStoreData(state),
    clientFormats: getClientFormats(state),
    isLoadingClientFormats: isLoadingClientFormats(state),
    storeAvailableSessions: getStoreAvailableSessionsState(state),
    isStoreAvailableSessionsLoading: isStoreAvailableSessionsLoading(state),
    connectionTime: getConnectionTimeState(state),
    isLoadingConnectionTime: isLoadingConnectionTime(state),
    storeMaps: getStoreMapsObject(state),
    isLoadingMaps: isLoadingMaps(state),

}), {
    setSnackAlert,
    getDigitalStoreExcel,
    getDigitalStoreInv,
    getDigitalStorePdf,
    getDSInfo,
    getUserStores,
    getTaskTypes,
    getStoreCategories,
    getAisleThumbnail,
    getExcelByDate,
    getUserSuppliers,
    getResumeDStore,
    getStoreAvailableSessions,
    getDSConnectionTime,
    getStoreMaps,
})(DigitalStore);
